<template>
  <!-- TODO: finalizar a implementação desse componente -->
  <!-- MOBILE -->
  <v-dialog
    v-if="$vuetify.breakpoint.mobile"
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="600"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        width="100%"
        outlined
        large
        text
        :color="secondaryColor"
        v-bind="attrs"
        v-on="on"
        height="50"
        :disabled="disabled"
        class="text-none text-body-1 font-weight-bold"
      >
        <v-icon left size="24"> ph-funnel-simple </v-icon>
        <v-badge
          v-if="isOrdering"
          :color="primaryColor"
          :content="appliedOrderingNumber"
          inline
        >
          Ordenar
        </v-badge>
        <span v-else> Ordenar </span>
      </v-btn>
    </template>
    <v-card class="pa-5">
      <slot />
    </v-card>
  </v-dialog>

  <!-- DESKTOP -->
  <v-menu v-else v-model="dialog" offset-y left :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        block
        outlined
        large
        text
        :color="secondaryColor"
        v-bind="attrs"
        v-on="on"
        height="50"
        class="text-none text-body-1 font-weight-bold mr-2"
      >
        <v-icon left size="24"> ph-funnel-simple </v-icon>
        <v-badge
          v-if="isOrdering"
          :color="primaryColor"
          :content="appliedOrderingNumber"
          inline
        >
          Ordenar
        </v-badge>
        <span v-else>Ordenar</span>
      </v-btn>
    </template>
    <v-card width="500" class="rounded-t-0 pa-3 pt-6">
      <slot />
    </v-card>
  </v-menu>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SortBySelector",

  props: {
    appliedOrderingNumber: { type: [Number], default: 0 },
    disabled: { type: Boolean, default: false },
    hide: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },

  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    ...mapGetters(["secondaryColor", "primaryColor"]),

    isOrdering() {
      return this.appliedOrderingNumber > 0;
    },
  },

  watch: {
    hideSort() {
      if (this.hide) {
        this.dialog = false;
      }
    },
  },
};
</script>
