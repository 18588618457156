<template>
  <div v-if="$vuetify.breakpoint.xs ? show : true">
    <div class="w-100 d-flex justify-center align-center px-3">
      <p
        v-if="!isCustomerTable"
        class="font-weight-medium text-body-1 ma-0 mr-5"
      >
        {{ $t("usersPage.showing") }}
        <span class="font-weight-black">{{ totalPageUsers }}</span>
        {{ $t("common.users") }}
      </p>
      <v-btn
        id="Sincronizar"
        v-if="!isCustomerTable"
        color="white"
        large
        class="text-none mr-2 mt-0 elevation-0 primary--text"
        style="outline: 1px solid #d4d6da; height: 44px"
        :loading="loadingSync"
        :disabled="loading"
        @click="$emit('sync')"
      >
        <v-icon left v-text="'ph-arrows-clockwise'" class="ml-2" size="24" />
      </v-btn>
      <v-text-field
        v-model="searchString"
        solo
        :label="label"
        :placeholder="placeholder"
        append-icon="ph-magnifying-glass"
        item-color="grey darken-1"
        style="
          outline: 1px solid #d4d6da;
          height: 44px !important;
          overflow: hidden;
        "
        flat
        hide-details
        clearable
        :background-color="`${isCustomerTable ? 'grey lighten-3' : ''}`"
      >
        <template #prepend-inner>
          <v-progress-circular
            color="primary"
            size="22"
            width="3"
            indeterminate
            v-if="loading"
            class="mr-2"
          />
        </template>

        <template #append>
          <slot name="append-search" />
        </template>
      </v-text-field>
      <div :class="`justify-end ma-0 pa-0`">
        <div class="pa-0 ma-0">
          <FilterSelector
            :applied-filters-number="filtersNumber"
            :loading="loading"
          >
            <UsersFiltersForm
              v-if="isUserTable"
              :applied="currentFilters"
              :loading="loading"
              @clear="clearUsersFilters"
              @search="searchFilters"
              :show-send-as="showSendAs"
            />

            <CustomersFiltersForm
              v-if="isCustomerTable"
              :applied="currentFilters"
              :loading="loading"
              @clear="clearCustomersFilters"
              @search="searchFilters"
            />
          </FilterSelector>
        </div>
        <div v-if="orderOptions" class="pa-0 ma-0 pl-4">
          <SortBySelector
            :hide="hideSort"
            :order-options="orderOptions"
            :loading="loading"
          >
            <SortByForm
              :loading="loading"
              :order-options="orderOptions"
              :order="currentOrder"
              @clear="clearSortBy"
              @sort-by="sortBy"
            />
          </SortBySelector>
        </div>
      </div>
    </div>
    <FilteringByRow
      :current-filters="updatedFilters"
      :is-user="isUserTable"
      :is-customer="isCustomerTable"
      @remove:filter="$emit('remove:filter', $event)"
      class="mt-3"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import CustomersFiltersForm from "@/components/forms/filters/CustomersFiltersForm";
import SortByForm from "@/components/forms/filters/SortByForm";
import UsersFiltersForm from "@/components/forms/filters/UsersFiltersForm";
import SortBySelector from "@/components/general/SortBySelector";
import FilterSelector from "@/components/general/filters/FilterSelector";
import FilteringByRow from "@/components/general/filters/FilteringByRow";

import {
  filterArrayToObject,
  filterObjectToFilterArray,
} from "@/helpers/services/filters";

export default {
  name: "SearchFilterBar",

  props: {
    label: { type: String, default: "Pesquisar por nome" },
    placeholder: { type: String, default: "Pesquisar por nome" },
    loading: { type: Boolean, default: false },
    appliedFilters: { type: Array, default: () => [] },
    appliedOrder: { type: Object, default: () => {} },
    orderOptions: { type: [Array, Boolean], default: false },
    isUserTable: { type: Boolean, default: false },
    showSendAs: { type: Boolean, default: false },
    isCustomerTable: { type: Boolean, default: false },
    show: { type: Boolean, default: false },
    totalPageUsers: { type: Number, default: 0 },
    loadingSync: { type: Boolean, default: false },
  },

  components: {
    FilterSelector,
    SortBySelector,
    UsersFiltersForm,
    SortByForm,
    CustomersFiltersForm,
    FilteringByRow,
  },

  data() {
    return {
      search: "",
      hideSort: false,
      timeout: null,
    };
  },

  computed: {
    ...mapGetters(["primaryColor", "secondaryColor"]),

    updatedFilters() {
      return this.appliedFilters;
    },

    searchString: {
      get() {
        return this.search;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (val && val.constructor === String) {
            this.search = val.trim();
          } else {
            this.search = val;
          }
        }, 600);
      },
    },

    currentOrder() {
      return this.appliedOrder;
    },

    /* get(): Format to an object with keys and values;
     * set(): Formar to an array of objects;
     */
    currentFilters: {
      get() {
        return filterArrayToObject(
          this.appliedFilters.filter((item) => {
            if (this.isUserTable) {
              return this.usersFiltersKeys.includes(item.value);
            }
            return this.customersFiltersKeys.includes(item.value);
          })
        );
      },
      set(newVal) {
        const updatedFilterArray = filterObjectToFilterArray(newVal);
        this.$emit("search-by", updatedFilterArray);
      },
    },

    filtersNumber() {
      return this.appliedFilters.length;
    },

    usersFiltersKeys() {
      return [
        "ou",
        "departament",
        "position",
        "is_google_admin",
        "applied_signature_key",
        "applied_vacation_key",
      ];
    },

    customersFiltersKeys() {
      return [
        "current_plan.*.plan.name",
        "current_plan.*.end_trial",
        "current_plan.*.status",
        "reseller_company.name",
        "current_plan.*.subscription_type",
        "suspended",
        "cancellation_date",
      ];
    },

    defaultSortBy() {
      return [
        {
          sortBy: ["name"],
          sortDesc: [false],
        },
      ];
    },
  },

  watch: {
    search() {
      this.$emit("input-search", this.search);
    },
  },

  methods: {
    clearSortBy() {
      this.$emit("sort-by", this.defaultSortBy);
    },

    sortBy(newSortBy) {
      const payload = {
        ...newSortBy,
      };

      this.$emit("sort-by", payload);

      this.closeSortDialog();
    },

    closeSortDialog() {
      this.hideSort = true;

      setTimeout(() => {
        this.hideSort = false;
      }, 2000);
    },

    clearUsersFilters() {
      let filterArray = filterObjectToFilterArray(this.currentFilters);

      const updated = filterArray.filter(
        (item) => !this.usersFiltersKeys.includes(item.value)
      );

      this.$emit("search-by", updated);
    },

    clearCustomersFilters() {
      let filterArray = filterObjectToFilterArray(this.currentFilters);

      const updated = filterArray.filter(
        (item) => !this.customersFiltersKeys.includes(item.value)
      );

      this.$emit("search-by", updated);
    },

    searchFilters(filters) {
      this.$emit("search-by", filterObjectToFilterArray(filters));
    },
  },
};
</script>
