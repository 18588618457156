<template>
  <v-card
    :class="`px-3 py-2 mt-4 ${$vuetify.theme.dark ? '' : 'grey lighten-4'}`"
    elevation="0"
    tile
  >
    <v-subheader
      v-if="!userHasEvaluated"
      :class="`text-body-2 font-weight-medium`"
    >
      Avalie sua experiência em {{ formatedFeatureName }}
    </v-subheader>

    <v-subheader v-else :class="`text-h6 font-weight-medium`">
      <v-icon v-text="'ph-fill ph-check-circle'" color="success" class="mr-4" />
      Avaliação enviada, obrigado!
    </v-subheader>

    <v-card v-if="!userHasEvaluated" tile flat color="transparent" width="280">
      <FeelingRatingPicker
        :selected="rating"
        @update="selectReaction"
        hide-text
      />
    </v-card>

    <v-dialog v-model="dialog" width="500" @click:outside="dialog = false">
      <v-card class="d-flex justify-center py-4">
        <FeedbackRating
          :action="featureSlugText"
          :selected="rating"
          show-dismiss
          rating-class="d-flex justify-start ml-1"
          width="400"
          @update="rating = $event"
          @evaluated="userHasEvaluated = $event"
          @close="dialog = false"
        />
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import FeedbackRating from "@/components/forms/FeedbackRating";
import FeelingRatingPicker from "@/components/forms/field_pickers/FeelingRatingPicker";

export default {
  name: "InlineFeatureRating",

  props: {
    selected: { type: [Number, String], default: 0 },
    featureName: { type: String, required: true },
    featureSlug: { type: String, default: "" },
  },

  components: { FeedbackRating, FeelingRatingPicker },

  data() {
    return {
      dialog: false,
      feedback: 0,
      showHideFeedback: false,
      userHasEvaluated: false,
    };
  },

  computed: {
    featureSlugText() {
      if (!this.featureSlug) {
        return this.featureName.toLowerCase().replace(/ /g, "_");
      }
      return this.featureSlug;
    },

    formatedFeatureName() {
      return this.featureName.toLowerCase();
    },
  },

  watch: {
    selected() {
      this.rating = this.selected;
    },
  },

  methods: {
    close() {
      this.showHideFeedback = false;
    },

    selectReaction(rating) {
      this.rating = rating;

      if (this.rating > 0) {
        this.dialog = true;
      }
    },
  },

  beforeMount() {
    this.rating = this.selected;
  },
};
</script>
