<template>
  <div>
    <v-btn
      :color="color"
      :elevation="elevation"
      :class="`${customClass}`"
      @click="fileImport"
      :loading="loading"
    >
      <v-icon v-if="icon" left v-text="icon" />
      {{ text }}
    </v-btn>
    <input
      ref="fileInput"
      :id="id"
      type="file"
      class="d-none"
      :accept="acceptedTypes"
      @change="handle"
    />
  </div>
</template>
<script>
import { userKeys } from "@/helpers/variables/files";
import Papa from "papaparse";
import { mapGetters } from "vuex";
export default {
  name: "Upload",
  props: {
    acceptedTypes: {
      type: String,
      default: ".csv",
    },
    id: {
      type: String,
      default: "fileUpload",
    },
    name: {
      type: String,
      default: "arquivo",
    },
    color: {
      type: String,
      default: "primary",
    },
    elevation: {
      type: [String, Number],
      default: "0",
    },
    customClass: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "Baixar ",
    },
    icon: {
      type: [String, Boolean],
      default: "ph-upload-simples",
    },
  },
  data() {
    return {
      file: "",
      content: [],
      parsed: false,
      loading: false,
      userKeys,
    };
  },
  computed: {
    ...mapGetters(["users", "usersCustomTagKeys", "usersCustomTags"]),

    isCsv() {
      const types = this.acceptedTypes + ",";
      return types.split(",").includes(".csv");
    },
    inputId() {
      return `${this.id}-input`;
    },
  },
  methods: {
    fileImport() {
      const inputField = document.getElementById(this.id);
      if (inputField) {
        inputField.click();
      }
    },
    handle({ target }) {
      if (this.isCsv) {
        this.parseFile(target.files[0]);
      }

      this.$refs.fileInput.value = "";
    },

    async parseFile(file) {
      await Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        error: function () {
          this.$emit("error", "ERROR_WHILE_DECODING_DATA");
        }.bind(this),
        complete: function (results) {
          this.content = results;
          this.parsed = true;
          if (results.data.length < 1) {
            this.$emit("error", "CSV_IS_EMPTY");
          } else {
            this.convertItemsIntoPayload();
          }
        }.bind(this),
      });
    },

    convertItemsIntoPayload() {
      const translatedKeys = this.content.data.map((user) =>
        this.parseObjectKeys(user)
      );
      const users = this.combineInfoToUser(translatedKeys);
      this.$emit("content", users);
    },

    parseObjectKeys(object) {
      return Object.entries(object).reduce((acc, item) => {
        const itemKey = item[0];
        let customTagKey = itemKey.toUpperCase().replace(/ /g, "_");
        const tagValue = item[1];

        if (this.usersCustomTagKeys.includes(customTagKey)) {
          const customTagToUpdate = { ...this.usersCustomTags[customTagKey] };
          customTagToUpdate.value = tagValue;

          acc["custom_tags"] = {
            ...acc.custom_tags,
            [customTagKey]: customTagToUpdate,
          };

          return acc;
        }

        acc[userKeys[itemKey]] = tagValue;
        return acc;
      }, {});
    },

    combineInfoToUser(content) {
      return content.reduce((acc, item) => {
        const userEmail = item.email;
        const userId = this.getUserId(userEmail);
        if (userId) {
          const currentItem = item;
          currentItem.id_google = userId;
          acc.push(currentItem);
        } else {
          this.$emit("error", "USER_ID_NOT_FOUND");
        }
        return acc;
      }, []);
    },

    getUserId(email) {
      let user = this.users.filter((user) => user.email === email);
      if (user.length > 0) {
        return user[0].id_google;
      }
    },
  },
};
</script>
