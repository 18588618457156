<template>
  <v-container
    style="height: 100%"
    class="d-flex justify-center align-center flex-column pb-0"
  >
    <v-row no-gutters>
      <v-col class="d-flex justify-end align-center">
        <v-img :src="groupsImage" width="100%" max-width="510px" />
      </v-col>
      <v-col class="d-flex justify-start align-center">
        <div class="ma-10" style="line-height: 1.25; max-width: 510px">
          <h1 class="line">
            Conecte sua equipe com grupos do Google Workspace.
          </h1>
          <h3 class="my-3 font-weight-regular">
            Utilizando grupos, a colaboração e comunicação entre suas equipes
            será ainda mais fácil. Caixa de entrada colaborativa,
            compartilhamento de conteúdo, facilidade no agendamento de reuniões
            e mais.
          </h3>
          <div class="py-5">
            <p>
              <v-icon size="26" color="success">ph-fill ph-check-circle</v-icon>
              Facilita a comunicação
            </p>
            <p>
              <v-icon size="26" color="success">ph-fill ph-check-circle</v-icon>
              Promove a colaboração
            </p>
            <p>
              <v-icon size="26" color="success">ph-fill ph-check-circle</v-icon>
              Permite a customização e configuração
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-btn
      height="58"
      class="mt-16 text-none text-h6 font-weight-bold px-8"
      large
      color="accent"
      @click="$emit('started')"
      >{{ $t("action.start") }}
      <v-icon size="24" right dark>ph-arrow-right</v-icon></v-btn
    >
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      groupsImage: require("@/assets/images/create_groups.png"),
    };
  },
};
</script>
