<template>
  <ResponsibleMenu custom-activator content-class="pa-0" window-width="160">
    <template v-slot:custom-activator>
      <v-btn
        color="accentSuite"
        :block="$vuetify.breakpoint.xs"
        large
        class="text-none text-body-1 white--text font-weight-medium elevation-0 pr-6 pl-0 py-4"
      >
        <v-tooltip bottom v-if="!hideCheckbox">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click.stop="selectMode">
              <v-icon
                v-text="checkboxState"
                size="24"
                color="white"
                v-bind="attrs"
                v-on="on"
              />
            </v-btn>
          </template>

          <span>{{ checkboxLabel }}</span>
        </v-tooltip>

        <v-divider v-if="!hideCheckbox" vertical class="mr-4 my-2" />

        <span :class="hideCheckbox ? 'pl-4' : ''">
          {{ $t("action.apply") }} {{ $vuetify.breakpoint.xs ? "ação" : "" }}
          {{ selectedNumber > 0 ? `${$t("common.to")} ` : "" }}
          {{ selectedNumber > 0 ? selectedNumber : "" }}
          {{
            selectedNumber > 0
              ? selectedNumber === 1
                ? $t("common.selected")
                : $t("common.selecteds")
              : ""
          }}
        </span>
        <v-icon v-text="'ph-fill ph-caret-down'" right size="24" />
      </v-btn>
    </template>

    <!-- AÇÕES DISPONÍVEIS -->
    <v-list style="min-width: 330px">
      <div
        v-if="!companyHasSignActive || !hasSignPermission"
        class="sign-permission-overlay"
      >
        <div v-if="!companyHasSignActive" class="sign-plan-text">
          <v-icon class="mr-3" v-text="'ph-fill ph-crown-simple'"></v-icon>
          <div>
            <p class="ma-0">{{ $t("common.availableOnleStandardPlan") }} +</p>
            <p class="ma-0 mt-1" style="font-size: 12px; font-weight: normal">
              {{ $t("common.bulkActionNotAvailable") }}
            </p>
          </div>
        </div>
        <div v-else-if="!hasSignPermission" class="sign-permission-text">
          <v-icon
            color="white"
            class="mr-3"
            v-text="'ph-fill ph-warning-circle'"
          ></v-icon>
          <div>
            <p class="ma-0">
              {{ $t("common.youDoNotHavePermissionEmail") }}
            </p>
            <p class="ma-0 mt-1" style="font-size: 12px; font-weight: normal">
              {{ $t("common.requestAccessToAdmin") }}
            </p>
          </div>
        </div>
      </div>
      <v-list-item v-if="selectedNumber === 0">
        <v-chip
          label
          color="primary"
          width="100%"
          class="mx-2 mt-2 font-weight-medium"
        >
          <v-icon dark v-text="'ph-fill ph-info'" left size="18" />
          {{ $t("common.selectLatest1User") }}
        </v-chip>
      </v-list-item>

      <v-list-item v-else @click="$emit('select:clear')">
        {{ $t("action.clearSelection") }}
      </v-list-item>

      <v-divider />

      <div v-for="(action, i) in actions" :key="i">
        <v-divider v-if="action.type === 'header' && i > 0" />
        <v-subheader v-if="action.type === 'header'">
          {{ action.title }}
        </v-subheader>
        <v-list-item
          v-else
          :disabled="
            selectedNumber === 0 || action.disabled || !hasSignPermission
          "
          @click="$emit(action.emit_action)"
        >
          <v-list-item-title>
            {{ action.title }}
          </v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </ResponsibleMenu>
</template>
<script>
import ResponsibleMenu from "@/components/base/ResponsibleMenu.vue";

import { mapGetters } from "vuex";

export default {
  name: "UsersBulkActionsMenu",

  props: {
    hideCheckbox: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    selectedNumber: { type: Number, default: 0 },
    totalUsersNumber: { type: Number, default: 0 },
    itensPerPage: { type: Number, default: 50 },
    disableForUsersWithoutVacationMessage: { type: Boolean, default: true },
  },

  components: { ResponsibleMenu },

  computed: {
    ...mapGetters([
      "displayOnlyPrimaryEmails",
      "hasSignPermission",
      "companyHasSignActive",
      "isCompanyMicrosoft",
    ]),

    actions() {
      return [
        {
          type: "header",
          title: this.$t("action.apply"),
          show: true,
        },
        {
          type: "action",
          title: this.$t("common.emailSignature"),
          emit_action: this.displayOnlyPrimaryEmails
            ? "apply:signature"
            : "apply:signature-to-secondary",
          show: true,
        },
        {
          type: "action",
          title: "Mensagem de férias",
          emit_action: "apply:vacation-message",
          show: this.showActionsOptions,
        },
        {
          type: "header",
          title: this.$t("action.create"),
          show: this.showActionsOptions,
        },
        {
          type: "action",
          title: this.$t("common.secondaryEmail"),
          emit_action: "create:secondary-emails",
          show: this.showActionsOptions,
        },
        {
          type: "header",
          title: this.$t("common.request"),
          show: this.showActionsOptions,
        },
        {
          type: "action",
          title: this.$t("action.requestUpdateUsersInfo"),
          emit_action: "update:user-tags",
          show: this.showActionsOptions,
        },
        {
          type: "header",
          title: this.$t("common.insert"),
          show: this.showActionsOptions,
        },
        {
          type: "action",
          title: this.$t("action.calendarsToUsers"),
          emit_action: "insert:users-calendar",
          show: this.showActionsOptions,
        },
        {
          type: "header",
          title: this.$t("common.remove"),
          show: this.showActionsOptions,
        },
        {
          type: "action",
          title: this.$t("action.removeVacationMessage"),
          emit_action: "remove:vacation-message",
          show: this.showActionsOptions,
          disabled: this.disableForUsersWithoutVacationMessage,
        },
      ].filter((item) => item.show);
    },

    showActionsOptions() {
      return this.displayOnlyPrimaryEmails && !this.isCompanyMicrosoft;
    },

    checkboxState() {
      if (this.allSelected && !this.loading && this.totalUsersNumber > 0) {
        return "ph-fill ph-check-square";
      } else if (this.parcialSelected) {
        return "ph-fill ph-check-square";
      } else {
        return "ph-square";
      }
    },

    checkboxLabel() {
      if (this.allSelected) {
        return this.$t("action.clearSelection");
      } else if (this.parcialSelected) {
        return this.$t("action.selectAllUsersOnThisPage");
      } else {
        return this.$t("action.selectAllUsersOnThisPage");
      }
    },

    parcialSelected() {
      return this.selectedNumber < this.itensPerPage && this.selectedNumber > 0;
    },

    allSelected() {
      if (
        this.totalUsersNumber < this.itensPerPage ||
        this.totalUsersNumber > this.itensPerPage
      ) {
        return this.selectedNumber == this.totalUsersNumber;
      }
      return this.selectedNumber == this.itensPerPage;
    },
  },

  watch: {
    displayOnlyPrimaryEmails() {
      this.$emit("select:clear");
    },
  },

  methods: {
    selectMode() {
      if (this.allSelected) {
        this.$emit("select:clear");
      } else if (this.parcialSelected) {
        this.$emit("select:all");
      } else {
        this.$emit("select:all");
      }
    },
  },
};
</script>

<style scoped>
.sign-permission-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
}

.sign-plan-text {
  background-color: var(--v-primarySign-base);
  border-radius: 5px;
  padding: 10px;
  padding-right: 20px;
  display: flex;
  align-items: flex-start;
  position: absolute;
  width: max-content;
  max-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: bold;
}

.sign-permission-text {
  background-color: var(--v-primarySuite-base);
  color: white;
  border-radius: 5px;
  padding: 10px;
  padding-right: 20px;
  display: flex;
  align-items: flex-start;
  position: absolute;
  width: max-content;
  max-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: bold;
}
</style>
