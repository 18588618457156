<template>
  <div class="mb-4">
    <v-card-subtitle class="font-weight-medium pt-0" v-if="subtitle">
      {{ $t("common.organizationUnit") }}
      <TooltipIcon
        icon="ph-info"
        color="secondary lighten-3"
        :label="$t('userInformations.organizationUnitDescription')"
      />
    </v-card-subtitle>
    <v-card-text>
      <v-autocomplete
        v-model="orgUnits"
        :items="allOrgUnits"
        :multiple="!singleChoice"
        :hide-selected="singleChoice"
        outlined
        :small-chips="singleChoice"
        item-text="name"
        item-value="path"
        hide-details
        clearable
        @input="update"
        :disabled="disabled"
      >
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <span v-if="singleChoice">{{ item.name }}</span>
          <v-chip
            v-else
            v-bind="attrs"
            :color="secondaryColor"
            :input-value="selected"
            label
            small
            dark
            placeholder="Nenhuma unidade organizacional selecionada"
          >
            <span class="pr-2">
              {{ item.name }}
            </span>
            <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
          </v-chip>
        </template>

        <template v-slot:item="{ item, on, attrs }">
          <v-list-item
            v-on="on"
            v-bind="attrs"
            :active-class="lightColors[productName]"
            :class="`mb-1`"
            max-width="200"
          >
            <v-list-item-icon v-if="!singleChoice">
              <v-icon
                :color="isSelected(item.name) ? `${primaryColor} darken-2` : ''"
                v-text="
                  isSelected(item.name)
                    ? 'ph-fill ph-check-square'
                    : 'ph-square'
                "
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-left font-weight-medium">{{
                item.name
              }}</v-list-item-title>
              <v-list-item-subtitle
                v-if="item.description"
                v-text="item.description"
                class="text-left"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="item.path"
                v-text="item.path"
                class="text-left"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-card-text>
  </div>
</template>

<script>
import { lightColors } from "@/helpers/variables/colors";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UosPicker",

  props: {
    returnPath: { type: Boolean, default: false },
    singleChoice: { type: Boolean, default: false },
    selected: { type: [Array, String], default: () => [] },
    subtitle: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      orgUnits: [],
      lightColors,
    };
  },

  computed: {
    ...mapGetters([
      "secondaryColor",
      "primaryColor",
      "allOrgUnits",
      "productName",
      "organizationalUnits",
    ]),

    selectedPath() {
      return this.singleChoice
        ? this.orgUnits
        : this.orgUnits.map((item) => item.path);
    },
  },
  watch: {
    orgUnits() {
      this.update();
    },
    selected: {
      handler() {
        this.orgUnits = this.selected;
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["getUos"]),
    isSelected(name) {
      if (this.singleChoice) {
        return name === this.orgUnits;
      } else {
        return this.orgUnits.find((item) => item.name === name);
      }
    },

    reset() {
      this.orgUnits = this.selected || [];
    },

    update() {
      if (this.returnPath) {
        this.$emit("update", this.selectedPath);
      } else {
        this.$emit("update", this.orgUnits[0]?.path);
      }
    },
  },

  async beforeMount() {
    this.reset();
    if (!this.organizationalUnits.length) {
      await this.getUos();
    }
  },
};
</script>
