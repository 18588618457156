<template>
  <v-container style="max-width: 1160px" class="pb-0">
    <div class="d-flex align-center justify-space-between">
      <div>
        <h1 class="font-weight-bold" style="font-size: 22px">
          Selecione os participantes desejados
        </h1>
        <p class="mt-3 mb-0" style="font-size: 14px">
          Escolha os membros que participarão do grupo, é possivel adicionar
          usuários e/ou outros grupos.
        </p>
      </div>
      <v-btn
        height="44"
        large
        color="primary"
        class="text-none text-body-1 font-weight-regular px-4"
        @click="addMemberDialog = true"
        >Adicionar participante
        <v-icon size="24" right dark>ph-plus</v-icon>
      </v-btn>
    </div>
    <v-card elevation="0" class="card mt-6">
      <v-data-table
        :headers="headers"
        :items="groupMembers"
        outline
        height="420px"
        fixed-header
      >
        <template v-slot:[`item.name`]="{ item }">
          <div class="py-3 d-flex align-center">
            <v-avatar left size="40">
              <img :src="item.photo || '@/assets/user-default.png'" />
            </v-avatar>
            <div class="ml-3" style="font-size: 14px">
              <p class="font-weight-medium ma-0">
                {{ item.name }}
              </p>
              <p class="grey--text text--darken-1 ma-0">
                {{ item.position || "Colaborador" }}
              </p>
            </div>
          </div>
        </template>
        <template v-slot:[`item.role`]="{ item }" style="max-width: 300px">
          <div class="d-flex align-center">
            <v-select
              :loading="loading"
              :disabled="loading"
              v-model="item.role"
              :items="roles"
              item-text="label"
              item-value="value"
              style="max-width: 250px; font-size: 14px"
              @change="updateMemberRole(item)"
            />
            <v-btn
              :loading="loading"
              :disabled="loading"
              @click="deleteMember(item)"
              class="ml-6 delete-icon"
              icon
              ><v-icon>ph-fill ph-trash</v-icon></v-btn
            >
          </div>
        </template>
      </v-data-table>
    </v-card>
    <div class="mt-10 d-flex justify-space-between">
      <v-btn
        height="52"
        large
        text
        class="text-none text-body-1 font-weight-bold px-8"
        @click="$emit('back')"
      >
        Voltar
      </v-btn>

      <v-btn
        height="52"
        large
        color="accent"
        class="text-none text-body-1 font-weight-bold px-8"
        :disabled="loading"
        @click="$emit('next')"
        :loading="loading"
      >
        Avançar
        <v-icon size="24" right dark>ph-arrow-right</v-icon>
      </v-btn>
    </div>

    <HeaderDialog
      title="Adicionar participante"
      :loading="loading"
      :show="addMemberDialog"
      @close="clearAddInfo()"
      @action="addMember()"
      width="800"
    >
      <template v-slot:body>
        <p class="mb-0 mt-3">
          Selecione o usuário que deseja inserir ao grupo:
        </p>
        <v-combobox
          v-model="selectedUser"
          :items="usersOptions"
          placeholder="usuario@dominio.com.br"
          item-text="email"
          clearable
        />
        <div class="mt-1 d-flex align-center">
          <v-checkbox
            class="ma-0"
            v-model="addGroupSendAs"
            label="Adicionar grupo como e-mail secundário ao usuário."
            hide-details
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-2" color="info" dark v-bind="attrs" v-on="on">
                ph-fill ph-info
              </v-icon>
            </template>
            <span
              >Adicionar o grupo como e-mail secundário permite que o usuário
              use o e-mail padrão dele para enviar mensagens a partir do
              endereço de e-mail do grupo.</span
            >
          </v-tooltip>
        </div>
      </template>
    </HeaderDialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    group: { type: Object, default: () => {} },
    groupMembers: { type: Array, default: () => [] },
  },

  data() {
    return {
      selectedUser: "",
      addMemberDialog: false,
      addGroupSendAs: false,
    };
  },

  computed: {
    ...mapGetters(["loading", "users"]),

    headers() {
      return [
        {
          text: "Nome",
          sortable: false,
          value: "name",
        },
        {
          text: "E-mail",
          sortable: false,
          value: "email",
        },
        {
          text: "Função do usuário",
          width: "270px",
          sortable: false,
          value: "role",
        },
      ];
    },

    roles() {
      return [
        {
          label: "Proprietário",
          value: "OWNER",
        },
        {
          label: "Administrador",
          value: "MANAGER",
        },
        {
          label: "Membro",
          value: "MEMBER",
        },
      ];
    },

    usersOptions() {
      return this.users.filter((user) => {
        return !this.groupMembers.some((member) => member.email === user.email);
      });
    },
  },

  methods: {
    ...mapActions([
      "addGoogleWorkspaceGroupMember",
      "updateGoogleWorkspaceGroupMember",
      "deleteGoogleWorkspaceGroupMember",
      "addSendAs",
    ]),

    clearAddInfo() {
      this.addGroupSendAs = false;
      this.selectedUser = "";
      this.addMemberDialog = false;
    },

    async addMember() {
      let payload = {
        group_email: this.group.email,
        user_email: this.selectedUser.email,
        role: "MEMBER",
      };
      if (this.addGroupSendAs) {
        let payloadSendAs = {
          email: this.group.email,
          id_user: this.selectedUser.id_google,
          name: this.group.name,
        };
        await this.addSendAs(payloadSendAs);
      }
      let groupMember = await this.addGoogleWorkspaceGroupMember(payload);
      let user = this.users.find((user) => user.email === groupMember.email);
      if (user) {
        user = { ...user, ...groupMember };
        this.$emit("addMember", user);
      } else {
        console.error(`User: ${groupMember.email} not found.`);
      }
      this.clearAddInfo();
    },

    async updateMemberRole(userConfig) {
      let payload = {
        group_email: this.group.email,
        user_email: userConfig.email,
        role: userConfig.role,
      };
      let groupMember = await this.updateGoogleWorkspaceGroupMember(payload);
      let user = this.users.find((user) => user.email === groupMember.email);
      if (user) {
        user = { ...user, ...groupMember };
        this.$emit("updateMember", user);
      } else {
        console.error(`User: ${groupMember.email} not found.`);
      }
      this.clearAddInfo();
    },

    async deleteMember(user) {
      let payload = {
        group_id: this.group.id,
        user_email: user.email,
      };
      await this.deleteGoogleWorkspaceGroupMember(payload);
      this.$emit("deleteMember", user);
      this.clearAddInfo();
    },
  },
};
</script>

<style scoped>
.delete-icon {
  color: grey;
}

.delete-icon:hover {
  color: red;
}
</style>
