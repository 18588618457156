<template>
  <v-card
    v-if="add"
    @click="$emit('openAddTemplate')"
    hover
    :height="listStyle ? 70 : cardHeight"
    outlined
    :class="`d-flex ${listStyle ? 'align-center justify-start px-4' : ''}`"
  >
    <v-icon :size="listStyle ? 35 : 90" color="accent">ph-plus</v-icon>

    <h3 :class="`text-center ${!listStyle ? 'mt-4' : 'ml-2'}`">
      Criar novo modelo de assinatura
    </h3>
  </v-card>

  <v-hover v-else v-slot="{ hover }">
    <v-card
      :height="
        listStyle ? 70 : isVacationTemplate ? cardHeight + 45 : cardHeight
      "
      :outlined="!flat"
      :flat="flat"
      :hover="!show"
      @click="
        isVacationTemplate
          ? $emit('selectCard', info.key)
          : $emit('selectCard', info.id)
      "
      :class="`d-flex ${
        listStyle
          ? 'align-center justify-start px-4'
          : 'flex-column justify-center px-4'
      }  ${selected ? 'selected-card border-large' : ''}`"
    >
      <div
        v-if="!listStyle"
        class="text-uppercase mt-3 caption d-flex align-center"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="accent"
              left
              v-text="custom ? 'ph-fill ph-note-pencil' : 'ph-fill ph-article'"
              v-bind="attrs"
              v-on="on"
              dense
            />
          </template>
          <span>{{
            custom ? $t("common.customizedModel") : $t("common.defaultModel")
          }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="isNewUserModel && !isVacationTemplate">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="accent"
              left
              v-text="'ph-user-gear'"
              v-bind="attrs"
              v-on="on"
              dense
            />
          </template>
          <span>Modelo definido para novos usuários</span>
        </v-tooltip>
        <span class="text-truncate" style="height: 20px">{{ info.name }}</span>
        <v-spacer />
        <div
          class="d-flex align-center justify-end"
          v-if="info.is_popular && info.signature_type === 'default'"
        >
          <v-icon small class="mr-1" color="red" v-text="'ph-fire'" />
          <strong class="red--text">
            {{ $t("applySignature.popular") }}
          </strong>
        </div>
      </div>

      <v-tooltip bottom v-if="listStyle">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="accent"
            left
            v-text="custom ? 'ph-fill ph-note-pencil' : 'ph-fill ph-article'"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <span>{{ custom ? "Modelo personalizado" : "Modelo padrão" }}</span>
      </v-tooltip>
      <v-tooltip
        bottom
        v-if="isNewUserModel && listStyle && !isVacationTemplate"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="accent"
            left
            v-text="'ph-user-gear'"
            v-bind="attrs"
            v-on="on"
            dense
          />
        </template>
        <span>Modelo definido para novos usuários</span>
      </v-tooltip>

      <div v-if="listStyle">{{ info.name }}</div>
      <div
        class="ml-2 d-flex align-center justify-end"
        v-if="info.is_popular && listStyle && info.signature_type === 'default'"
      >
        <v-icon small class="mr-1" color="accent" v-text="'ph-fire'" />
        <strong class="accent--text">
          {{ $t("applySignature.popular") }}
        </strong>
      </div>

      <v-card
        v-if="!listStyle && !isVacationTemplate"
        :height="cardHeight + 20"
        width="100%"
        disabled
        flat
        tile
        class="pa-0 ma-0 overflow-hidden transparent"
      >
        <v-card-text
          v-html="htmlSignature"
          :class="`${
            hasHtml ? 'preview-signature-scale orange-border' : ''
          } pa-0 ma-0 transparent`"
        />
      </v-card>

      <v-card
        v-if="!listStyle && isVacationTemplate"
        :height="cardHeight + 20"
        width="100%"
        flat
        tile
        class="pa-0 ma-0 overflow-hidden transparent"
      >
        <v-card-subtitle class="px-0 mx-0">
          <span class="caption grey--text text--darken-1">
            <strong>Assunto:</strong>
            {{ info.response_subject }}</span
          >
        </v-card-subtitle>
        <v-card-text class="px-0">
          <span class="caption grey--text text--darken-1"
            ><strong>Mensagem:</strong></span
          >

          <span v-html="info.content"></span>
        </v-card-text>
      </v-card>

      <v-spacer />
      <v-card-actions
        v-if="hover && !isUsersApply && !noActions"
        class="caption grey--text text--darken-1 px-0"
      >
        <v-tooltip v-if="custom" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="loading"
              @click.stop="$emit('edit', info)"
              class="mr-1"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon v-text="'ph-pencil-simple'" />
            </v-btn>
          </template>
          <span>{{ $t("applySignature.editModel") }}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="loading"
              @click.stop="$emit('duplicate', info)"
              icon
              v-bind="attrs"
              v-on="on"
              class="mr-1"
            >
              <v-icon v-text="'ph-copy-simple'" />
            </v-btn>
          </template>
          <span>{{ $t("applySignature.duplicateModel") }}</span>
        </v-tooltip>

        <v-tooltip v-if="isConecta || custom" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="loading"
              @click.stop="$emit('delete', info)"
              icon
              v-bind="attrs"
              v-on="on"
              class="mr-1"
            >
              <v-icon v-text="'ph-trash'" />
            </v-btn>
          </template>
          <span>{{ $t("applySignature.deleteModel") }}</span>
        </v-tooltip>
      </v-card-actions>

      <v-card-actions v-else class="caption text--darken-1 px-0">
        {{ $t("common.lastUpdate") }}
        {{ formatDateAndHour(info.updated_at, true) }}
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import {
  fixDriveUrls,
  fluxTemplateToPreview,
} from "@/helpers/services/tagPreview";
import { formatDateAndHour } from "@/helpers/services/utils";
import { mapGetters } from "vuex";

export default {
  name: "CardTemplate",
  props: {
    info: { type: Object, default: () => {} },
    custom: { type: Boolean, default: false },
    show: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    add: { type: Boolean, default: false },
    flat: { type: Boolean, default: false },
    listStyle: { type: Boolean, default: false },
    isVacationTemplate: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    noActions: { type: Boolean, default: false },
  },
  data() {
    return {
      html: "",
      noHtml: `<i>${this.$t("applySignature.emptySignature")}</i>`,
    };
  },
  beforeMount() {
    this.initialTemplateConfig();
  },
  computed: {
    ...mapGetters(["isConecta", "company"]),

    cardHeight() {
      return this.$vuetify.breakpoint.xl ? 190 : 160;
    },

    htmlSignature: {
      get() {
        return fixDriveUrls(this.html);
      },
      set(new_value) {
        this.html = new_value;
      },
    },

    isUsersApply() {
      return this.$route.path === "/sign/users/apply";
    },

    hasHtml() {
      return this.info && this.info.html !== "";
    },

    isNewUserModel() {
      return this.info.id == this.company.new_users_signature;
    },
  },

  watch: {
    "info.html"() {
      this.initialTemplateConfig();
    },
  },
  methods: {
    formatDateAndHour,

    initialTemplateConfig() {
      if (this.info) {
        if (this.info.content) {
          this.htmlSignature = this.info.content
            ? fluxTemplateToPreview(this.info.content)
            : this.noHtml;
        } else {
          this.htmlSignature = this.info.html
            ? fluxTemplateToPreview(this.info.html)
            : this.noHtml;
        }
      }
    },
  },
};
</script>
<style scoped>
.selected-card {
  border-color: rgb(52, 169, 236);
}
.preview-signature-scale {
  position: absolute;
  max-width: 100%;
  -webkit-transform-origin: 0 0;
  border: 1px solid orange;
}
@media screen and (max-width: 1646px) {
  .preview-signature-scale {
    transform: scale(0.35);
  }
}
@media screen and (min-width: 1647px) {
  .preview-signature-scale {
    transform: scale(0.4);
  }
}
</style>
