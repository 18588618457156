<template>
  <!-- MOBILE -->
  <v-dialog
    v-if="$vuetify.breakpoint.mobile"
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="600"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!customActivator"
        width="100%"
        :outlined="buttonHasText"
        :large="buttonHasText"
        :text="buttonHasText && !outlined"
        :icon="!buttonHasText && buttonHasIcon"
        :color="secondaryColor"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        class="text-none text-body-1 font-weight-bold mb-4"
      >
        <v-icon
          v-if="buttonHasIcon"
          :left="buttonHasText"
          size="24"
          :class="`${buttonHasText ? 'mr-3' : ''}`"
        >
          {{ buttonIcon }}
        </v-icon>
        <span v-if="buttonHasText"> {{ buttonText }} </span>
      </v-btn>
      <span v-else v-bind="attrs" v-on="on">
        <slot name="custom-activator" />
      </span>
    </template>
    <v-card :width="windowWidth" :class="`${contentClass}`">
      <slot />
    </v-card>
  </v-dialog>

  <!-- DESKTOP -->
  <v-menu
    v-else
    v-model="dialog"
    offset-y
    left
    :close-on-content-click="false"
    :width="windowWidth"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!customActivator"
        :outlined="buttonHasText"
        :large="buttonHasText"
        :text="buttonHasText && !outlined"
        :icon="!buttonHasText && buttonHasIcon"
        :color="secondaryColor"
        v-bind="attrs"
        v-on="on"
        class="text-none text-body-1 font-weight-bold"
      >
        <v-icon
          v-if="buttonHasIcon"
          :left="buttonHasText"
          size="24"
          :class="`${buttonHasText ? 'mr-3' : ''}`"
        >
          {{ buttonIcon }}
        </v-icon>
        <span v-if="buttonHasText"> {{ buttonText }} </span>
        <v-icon v-if="showMenuIcon" right v-text="'ph-fill ph-caret-down'" />
      </v-btn>
      <span v-else v-bind="attrs" v-on="on">
        <slot name="custom-activator" />
      </span>
    </template>
    <v-card :class="`${contentClass}`">
      <slot />
    </v-card>
  </v-menu>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ResponsibleMenu",

  props: {
    buttonIcon: { type: [String, Boolean], default: false },
    buttonText: { type: [String, Boolean], default: false },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    showHide: { type: Boolean, default: false },
    showMenuIcon: { type: Boolean, default: false },
    windowWidth: { type: [String, Number], default: "500" },
    contentClass: { type: String, default: "pa-3" },
    outlined: { type: Boolean, default: false },
    customActivator: { type: Boolean, default: false },
  },

  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    ...mapGetters(["secondaryColor", "primaryColor"]),

    buttonHasText() {
      return this.buttonText ? true : false;
    },

    buttonHasIcon() {
      return this.buttonIcon ? true : false;
    },
  },

  watch: {
    showHide() {
      if (!this.customActivator) {
        this.dialog = this.showHide;
      }
    },

    dialog() {
      if (!this.customActivator) {
        this.$emit("update:show", this.dialog);
      }
    },
  },
};
</script>
