<template>
  <v-form style="width: 100%" :disabled="loading" ref="customersFilters">
    <PlanPicker
      v-model="filteringBy['current_plan.*.plan.name']"
      :selected="filteringBy['current_plan.*.plan.name']"
      @update="filteringBy['current_plan.*.plan.name'] = $event"
      single-choice
    />

    <v-card-subtitle class="font-weight-medium pt-0 pb-0">
      Status
      <TooltipIcon
        icon="ph-info"
        color="secondary lighten-3"
        label="Status do plano do cliente"
      />
    </v-card-subtitle>
    <v-card-text class="mb-5">
      <v-radio-group
        v-model="filteringBy['current_plan.*.status']"
        row
        hide-details
      >
        <v-radio
          v-for="(status, i) in statusList"
          :key="i"
          :label="status.name"
          :value="status.value"
          hide-details
        />
      </v-radio-group>
    </v-card-text>

    <EndTrialPicker
      v-model="filteringBy['current_plan.*.end_trial']"
      :selected="filteringBy['current_plan.*.end_trial']"
      @update="filteringBy['current_plan.*.end_trial'] = $event"
    />

    <SubscriptionTypePicker
      v-model="filteringBy['current_plan.*.subscription_type']"
      :selected="filteringBy['current_plan.*.subscription_type']"
      @update="filteringBy['current_plan.*.subscription_type'] = $event"
    />

    <PartnerPicker
      v-model="filteringBy['reseller_company.name']"
      :selected="filteringBy['reseller_company.name']"
      @update="filteringBy['reseller_company.name'] = $event"
    />
    <ClientStatusPicker
      :selected="clientStatusSelected"
      @update="filterByClientStatus"
    />

    <v-card-actions class="justify-end">
      <v-btn
        text
        color="secondary lighten-3"
        rounded
        large
        elevation="0"
        class="text-none text-body-1 font-weight-medium px-4"
        @click="clear"
      >
        Limpar
      </v-btn>
      <v-spacer />
      <v-btn
        :dark="!emptySearch || !!Object.keys(applied).length"
        :color="primaryColor"
        :disabled="emptySearch && !Object.keys(applied).length"
        rounded
        large
        elevation="0"
        class="text-none text-body-1 font-weight-medium pl-4 pr-6"
        :loading="loading"
        @click="$emit('search', filteringBy)"
      >
        <v-icon v-text="'ph-magnifying-glass'" left />
        Pesquisar
      </v-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import ClientStatusPicker from "@/components/forms/field_pickers/ClientStatusPicker.vue";
import EndTrialPicker from "@/components/forms/field_pickers/EndTrialPicker";
import PartnerPicker from "@/components/forms/field_pickers/PartnerPicker";
import PlanPicker from "@/components/forms/field_pickers/PlanPicker";
import SubscriptionTypePicker from "@/components/forms/field_pickers/SubscriptionTypePicker";

import { mapGetters } from "vuex";

import {
  clientStatusList,
  statusList,
} from "@/helpers/variables/subscriptionPlans";

export default {
  name: "CustomersFiltersForm",

  props: {
    applied: { type: Object, default: () => {} },
    loading: { type: Boolean, default: false },
  },

  components: {
    PlanPicker,
    EndTrialPicker,
    SubscriptionTypePicker,
    PartnerPicker,
    ClientStatusPicker,
  },

  data() {
    return {
      localFilters: {},
      statusList,
      allowedStatus: clientStatusList.map((status) => status.value),
    };
  },

  computed: {
    ...mapGetters(["secondaryColor", "primaryColor"]),

    filteringBy: {
      get() {
        return this.localFilters;
      },
      set(newVal) {
        this.localFilters = newVal;
      },
    },

    emptySearch() {
      return Object.values(this.filteringBy).every(
        (item) => item === "" || item === null
      );
    },

    defaultFilters() {
      return {
        "current_plan.*.plan.name": "",
        "current_plan.*.end_trial": "",
        "current_plan.*.status": "",
        "reseller_company.name": "",
        "current_plan.*.subscription_type": "",
      };
    },

    clientStatusSelected() {
      return Object.keys(this.applied).filter((filter) =>
        this.allowedStatus.includes(filter)
      );
    },
  },

  watch: {
    applied() {
      this.update();
    },
  },

  methods: {
    clear() {
      this.$nextTick(() => {
        if (this.$refs.customersFilters) {
          this.$refs.customersFilters.reset();
        }
      });
      this.reset();

      this.$emit("clear", this.defaultFilters);
    },

    update() {
      if (this.applied && Object.keys(this.applied).length > 0) {
        const validAppliedFilters = Object.entries(this.applied).reduce(
          (acc, item) => {
            const key = item[0];
            const value = item[1];

            if (value !== null) {
              acc[key] = value;
            }

            return acc;
          },
          {}
        );

        this.filteringBy = { ...this.defaultFilters, ...validAppliedFilters };
      } else {
        this.reset();
      }
    },

    reset() {
      this.filteringBy = Object.assign({}, this.defaultFilters);
    },

    filterByClientStatus(statusSelecteds) {
      if (!statusSelecteds) {
        return;
      }

      const newFilters = {};
      this.allowedStatus.map((status) => {
        if (statusSelecteds.includes(status)) {
          newFilters[status] = "true";
        } else {
          newFilters[status] = "";
        }
      });

      this.filteringBy = { ...this.filteringBy, ...newFilters };
    },
  },

  beforeMount() {
    this.update();
  },
};
</script>
