<template>
  <v-container style="max-width: 1160px" class="pb-0">
    <div class="d-flex align-center">
      <h1 class="font-weight-bold" style="font-size: 22px">
        Configurações do grupo
      </h1>
    </div>
    <div class="mt-3" style="font-size: 14px">
      <p>
        Defina as permissões de acesso dos participantes do grupo. Participantes
        externos não podem ver quem são os participantes, nem pesquisar o
        conteúdo do grupo.
      </p>
    </div>
    <div>
      <v-radio-group v-model="localGroupTypeSelected" row>
        <v-radio
          class="mr-10"
          v-for="group in groupsTypes"
          :key="group.key"
          :value="group.key"
          color="accent"
          @click="selectGroupType(group)"
        >
          <template v-slot:label>
            <div>
              <p
                class="ma-0 font-weight-medium"
                style="font-size: 15px; color: black"
              >
                {{ group.title }}
              </p>
            </div>
          </template>
        </v-radio>
      </v-radio-group>
      <div v-for="group in groupsTypes" :key="group.key">
        <div v-if="groupTypeSelected === group.key" class="d-flex align-center">
          <v-icon color="primary" size="40">{{ group.icon }}</v-icon>
          <div class="ml-3">
            <p class="ma-0 font-weight-medium">{{ group.title }}</p>
            <p class="ma-0">
              {{ group.subtitle }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <v-form class="mt-10">
      <div v-for="input in inputsOptions" :key="input.key">
        <span
          :class="`${isCustomSelected ? '' : 'grey--text'} font-weight-medium`"
          >{{ input.label }}</span
        >
        <p v-if="input.subtitle" class="ma-0">
          <small>{{ input.subtitle }}</small>
        </p>
        <v-select
          v-model="localGroupConfig[input.model]"
          @change="$emit('updateGroupConfig', localGroupConfig)"
          :items="input.options"
          outlined
          item-text="label"
          item-value="value"
          :disabled="!isCustomSelected"
        >
          <template v-slot:selection="{ item }">
            <v-list-item-content v-if="item">
              <v-list-item-title>
                <v-icon
                  :disabled="!isCustomSelected"
                  class="mr-2"
                  v-if="item.icon"
                  >{{ item.icon }}</v-icon
                >
                {{ item.label }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon class="mr-2" v-if="item.icon">{{ item.icon }}</v-icon>
                {{ item.label }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </div>
    </v-form>
    <v-alert style="font-size: 14px" text type="info"
      ><strong>Observação:</strong> você sempre pode adicionar pessoas externas
      a grupos no Google Admin Console, mesmo que isso não seja permitido pela
      configuração de associação externa.</v-alert
    >
    <div class="d-flex align-center">
      <v-switch
        color="accent"
        v-model="localGroupConfig.allowExternalMembers"
        inset
        @change="$emit('updateGroupConfig', localGroupConfig)"
      ></v-switch>
      <div>
        <p class="ma-0 font-weight-medium">
          Permitir participantes fora da sua organização
        </p>
        <small>Ative para permitir pessoas externas no grupo.</small>
      </div>
    </div>
    <div class="mt-10 d-flex justify-space-between">
      <v-btn
        height="52"
        large
        text
        class="text-none text-body-1 font-weight-bold px-8"
        @click="$emit('back')"
      >
        Voltar
      </v-btn>

      <div>
        <v-btn
          height="52"
          large
          color="primary"
          class="text-none text-body-1 font-weight-bold px-8 mr-3"
          outlined
          :disabled="loading"
          @click="$emit('next')"
          :loading="loading"
        >
          Pular
        </v-btn>
        <v-btn
          height="52"
          large
          color="accent"
          class="text-none text-body-1 font-weight-bold px-8"
          :disabled="loading"
          @click="updateGroup()"
          :loading="loading"
        >
          Atualizar grupo e avançar
          <v-icon size="24" right dark>ph-arrow-right</v-icon>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    group: { type: Object, default: () => {} },
    groupConfig: { type: Object, default: () => {} },
    groupTypeSelected: { type: String, default: "public" },
  },

  data() {
    return {
      localGroupTypeSelected: this.groupTypeSelected,
      localGroupConfig: { ...this.groupConfig },
    };
  },

  methods: {
    ...mapActions(["updateGoogleWorkspaceGroup"]),

    async updateGroup() {
      let group_payload = {
        ...this.group,
        ...this.groupConfig,
      };
      let group = await this.updateGoogleWorkspaceGroup(group_payload);
      if (group && Object.keys(group).length > 0) {
        this.$emit("next");
      }
    },

    selectGroupType(groupType) {
      this.localGroupTypeSelected = groupType.key;
      if (groupType.configs) {
        this.localGroupConfig = {
          ...this.localGroupConfig,
          ...groupType.configs,
        };
      }
      this.$emit("updateGroupType", this.localGroupTypeSelected);
      this.$emit("updateGroupConfig", this.localGroupConfig);
    },
  },

  computed: {
    ...mapGetters(["domains", "loading"]),

    isCustomSelected() {
      return this.groupTypeSelected === "custom";
    },

    groupsTypes() {
      return [
        {
          title: "Público",
          subtitle:
            "Qualquer pessoa na organização pode postar e participar do grupo.",
          icon: "ph-fill ph-globe-hemisphere-west",
          key: "public",
          configs: {
            whoCanContactOwner: "ANYONE_CAN_CONTACT",
            whoCanViewGroup: "ALL_IN_DOMAIN_CAN_VIEW",
            whoCanPostMessage: "ALL_IN_DOMAIN_CAN_POST",
            whoCanViewMembership: "ALL_IN_DOMAIN_CAN_VIEW",
            whoCanModerateMembers: "OWNERS_AND_MANAGERS",
            whoCanJoin: "ALL_IN_DOMAIN_CAN_JOIN",
          },
        },
        {
          title: "Equipe",
          subtitle:
            "Qualquer pessoa na organização pode postar no grupo, mas precisa pedir para participar.",
          icon: "ph-fill ph-users-three",
          key: "team",
          configs: {
            whoCanContactOwner: "ANYONE_CAN_CONTACT",
            whoCanViewGroup: "ALL_IN_DOMAIN_CAN_VIEW",
            whoCanPostMessage: "ALL_IN_DOMAIN_CAN_POST",
            whoCanViewMembership: "ALL_IN_DOMAIN_CAN_VIEW",
            whoCanModerateMembers: "OWNERS_AND_MANAGERS",
            whoCanJoin: "CAN_REQUEST_TO_JOIN",
          },
        },
        {
          title: "Somente anuncio",
          subtitle:
            "Somente proprietários e gerentes do grupo podem postar, e qualquer pessoa na organização pode participar.",
          icon: "ph-fill ph-megaphone",
          key: "announcement",
          configs: {
            whoCanContactOwner: "ANYONE_CAN_CONTACT",
            whoCanViewGroup: "ALL_IN_DOMAIN_CAN_VIEW",
            whoCanPostMessage: "ALL_MANAGERS_CAN_POST",
            whoCanViewMembership: "ALL_MANAGERS_CAN_VIEW",
            whoCanModerateMembers: "OWNERS_AND_MANAGERS",
            whoCanJoin: "ALL_IN_DOMAIN_CAN_JOIN",
          },
        },
        {
          title: "Restrito",
          subtitle:
            "Somente proprietários, gerentes e participantes do grupo podem postar, e as pessoas na organização precisam pedir para participar.",
          icon: "ph-fill ph-eye-slash",
          key: "restrict",
          configs: {
            whoCanContactOwner: "ANYONE_CAN_CONTACT",
            whoCanViewGroup: "ALL_MEMBERS_CAN_VIEW",
            whoCanPostMessage: "ALL_MEMBERS_CAN_POST",
            whoCanViewMembership: "ALL_MEMBERS_CAN_VIEW",
            whoCanModerateMembers: "OWNERS_AND_MANAGERS",
            whoCanJoin: "CAN_REQUEST_TO_JOIN",
          },
        },
        {
          title: "Personalizado",
          subtitle: "Selecione as configurações de acesso que desejar.",
          icon: "ph-fill ph-list-star",
          key: "custom",
        },
      ];
    },
    inputsOptions() {
      return [
        {
          label: "Quem pode entrar em contato com os próprietarios do grupo",
          model: "whoCanContactOwner",
          key: 1,
          options: [
            {
              label: "Todos as pessoas da sua empresa podem entrar em contato.",
              value: "ALL_IN_DOMAIN_CAN_CONTACT",
              icon: "ph-fill ph-buildings",
            },
            {
              label: "Apenas administradores podem entrar em contato.",
              value: "ALL_MANAGERS_CAN_CONTACT",
              icon: "ph-fill ph-user",
            },
            {
              label: "Todos os membros do grupo podem entrar em contato.",
              value: "ALL_MEMBERS_CAN_CONTACT",
              icon: "ph-fill ph-users-three",
            },
            {
              label: "Qualquer usuário da internet pode entrar em contato.",
              value: "ANYONE_CAN_CONTACT",
              icon: "ph-globe-simple",
            },
          ],
        },
        {
          label: "Quem pode ver as conversas",
          model: "whoCanViewGroup",
          key: 2,
          options: [
            {
              label:
                "Qualquer usuário da Internet pode ver as mensagens do grupo.",
              value: "ANYONE_CAN_VIEW",
              icon: "ph-globe-simple",
            },
            {
              label:
                "Qualquer pessoa da sua empresa pode ver as mensagens deste grupo.",
              value: "ALL_IN_DOMAIN_CAN_VIEW",
              icon: "ph-fill ph-buildings",
            },
            {
              label:
                "Somente os participantes podem ver as mensagens do grupo.",
              value: "ALL_MEMBERS_CAN_VIEW",
              icon: "ph-fill ph-users-three",
            },
            {
              label:
                "Somente administradores do grupo podem ver as mensagens deste grupo.",
              value: "ALL_MANAGERS_CAN_VIEW",
              icon: "ph-fill ph-user",
            },
          ],
        },
        {
          label: "Quem pode postar",
          model: "whoCanPostMessage",
          key: 3,
          options: [
            {
              label:
                "Administradores, inclusive proprietários de grupos, podem postar uma mensagem.",
              value: "ALL_MANAGERS_CAN_POST",
              icon: "ph-fill ph-user",
            },
            {
              label: "Qualquer participante do grupo pode postar uma mensagem.",
              value: "ALL_MEMBERS_CAN_POST",
              icon: "ph-fill ph-users-three",
            },
            {
              label:
                "Somente os proprietários do grupo podem postar uma mensagem.",
              value: "ALL_OWNERS_CAN_POST",
              icon: "ph-fill ph-user-gear",
            },
            {
              label: "Qualquer pessoa da sua empresa pode postar uma mensagem.",
              value: "ALL_IN_DOMAIN_CAN_POST",
              icon: "ph-fill ph-buildings",
            },
            {
              label:
                "Qualquer qualquer usuário da Internet que não seja da sua empresa poderá acessar o serviço do Grupos do Google e postar uma mensagem.",
              value: "ANYONE_CAN_POST",
              icon: "ph-globe-simple",
            },
          ],
        },
        {
          label: "Quem pode ver os participantes",
          model: "whoCanViewMembership",
          key: 4,
          options: [
            {
              label:
                "Qualquer pessoa na sua empresa pode ver a lista de participantes do grupo.",
              value: "ALL_IN_DOMAIN_CAN_VIEW",
              icon: "ph-fill ph-buildings",
            },
            {
              label:
                "Os participantes do grupo podem ver a lista de participantes.",
              value: "ALL_MEMBERS_CAN_VIEW",
              icon: "ph-fill ph-users-three",
            },
            {
              label:
                "Somente administradores do grupo podem visualizar a lista de participantes.",
              value: "ALL_MANAGERS_CAN_VIEW",
              icon: "ph-fill ph-user",
            },
          ],
        },
        {
          label: "Quem pode gerenciar os participantes",
          model: "whoCanModerateMembers",
          key: 5,
          options: [
            {
              label:
                "Qualquer membro do grupo pode gerenciar os participantes.",
              value: "ALL_MEMBERS",
              icon: "ph-fill ph-users-three",
            },
            {
              label:
                "Apenas administradores do grupo podem gerenciar os participantes.",
              value: "OWNERS_AND_MANAGERS",
              icon: "ph-fill ph-user",
            },
            {
              label:
                "Somente os proprietários do grupo podem gerenciar os participantes.",
              value: "OWNERS_ONLY",
              icon: "ph-fill ph-user-gear",
            },
          ],
        },
        {
          label: "Quem pode participar do grupo",
          subtitle: "Escolha como as pessoas poderão se juntar ao grupo.",
          model: "whoCanJoin",
          key: 6,
          options: [
            {
              label: "Qualquer pessoa da sua empresa pode participar.",
              value: "ALL_IN_DOMAIN_CAN_JOIN",
              icon: "ph-fill ph-buildings",
            },
            {
              label: "Somente convidados podem participar.",
              value: "INVITED_CAN_JOIN",
              icon: "ph-fill ph-user-plus",
            },
            {
              label:
                "As pessoas que não são participantes podem solicitar um convite para participar",
              value: "CAN_REQUEST_TO_JOIN",
              icon: "ph-fill ph-user",
            },
          ],
        },
      ];
    },
  },
};
</script>
