<template>
  <!-- MOBILE -->
  <v-dialog
    v-if="$vuetify.breakpoint.mobile"
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="600"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        width="100%"
        large
        color="white"
        v-bind="attrs"
        v-on="on"
        height="44"
        :disabled="disabled"
        class="text-none text-body-2 font-weight-medium ml-2 mt-0 elevation-0 primary--text"
        style="outline: 1px solid #d4d6da"
      >
        <v-icon left size="24"> ph-funnel-simple </v-icon>
        <v-badge
          v-if="hasFilters"
          color="primary"
          :content="appliedFiltersNumber"
          inline
        >
          {{ $t("filters.filters") }}
        </v-badge>
        <span v-else> {{ $t("filters.filters") }} </span>
      </v-btn>
    </template>
    <div>
      <v-card class="pa-4">
        <slot />
      </v-card>
    </div>
  </v-dialog>

  <!-- DESKTOP -->
  <v-menu v-else v-model="dialog" offset-y left :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        large
        color="white"
        v-bind="attrs"
        v-on="on"
        class="text-none text-body-1 font-weight-medium px-10 ml-2 mt-0 elevation-0 primary--text"
        style="outline: 1px solid #d4d6da; height: 44px"
      >
        <v-icon left size="24"> ph-funnel-simple </v-icon>
        <v-badge
          v-if="hasFilters"
          color="primary"
          :content="appliedFiltersNumber"
          inline
        >
          {{ $t("filters.filters") }}
        </v-badge>
        <span v-else> {{ $t("filters.filters") }}</span>
      </v-btn>
    </template>
    <v-card width="500" class="rounded-t-0 pa-3 pt-6">
      <slot />
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: "UsersFilterSelector",

  props: {
    title: { type: String, default: "Aplicar filtro para usuários" },
    appliedFiltersNumber: { type: [Number], default: 0 },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },

  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    hasFilters() {
      return this.appliedFiltersNumber > 0;
    },
  },
};
</script>
