<template>
  <v-dialog
    v-model="showSelectSignatureModel"
    fullscreen
    hide-overlay
    height="100%"
    scrollable
    transition="dialog-bottom-transition"
    persistent
    no-click-animation
  >
    <v-card class="grey lighten-3 elevation-0" tile>
      <v-toolbar dark color="primary" flat tile max-height="65">
        <v-btn icon dark @click="resetEmailTemplateApply">
          <v-icon v-text="'ph-x'" />
        </v-btn>
        <v-toolbar-title v-text="$t('applySignature.applyEmailSignature')" />
      </v-toolbar>

      <v-card
        tile
        class="elevation-0 transparent overflow-y-auto"
        :loading="showLoading"
      >
        <v-stepper v-model="steps" class="py-0 elevation-0">
          <v-stepper-header
            class="d-flex justify-start mt-0"
            style="height: 56px"
          >
            <v-stepper-step
              color="accent"
              :complete="steps > 1"
              step="1"
              :class="`ml-10 mr-6 ${steps > 1 ? 'text-link' : ''}`"
              style="height: 56px"
              @click="steps > 1 ? (steps = 1) : null"
            >
              {{ $t("applySignature.selectSignatureModel") }}
            </v-stepper-step>

            <v-stepper-step
              color="accent"
              step="2"
              class="mr-6"
              :complete="steps > 2"
              style="height: 56px"
            >
              {{ $t("applySignature.viewEmailSignature") }}
            </v-stepper-step>

            <v-stepper-step
              color="accent"
              step="3"
              class="mr-6"
              style="height: 56px"
            >
              {{ $t("common.confirmChanges") }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items class="grey lighten-3 elevation-0">
            <v-stepper-content step="1" class="grey lighten-3 elevation-0">
              <v-card
                color="white"
                class="mx-auto px-4 pb-4 pt-2 elevation-0 grey--text text--darken-1"
                tile
                :width="contentWidth"
                outlined
              >
                <v-card-actions>
                  <v-text-field
                    prepend-inner-icon="ph-magnifying-glass"
                    :label="$t('common.searchModel')"
                    v-model="searchSignTemplate"
                    color="accent"
                    :disabled="showLoading"
                  />
                </v-card-actions>

                <v-tabs vertical color="accent">
                  <v-tab class="d-flex justify-start"
                    >{{ $t("common.all") }}
                  </v-tab>
                  <v-tab class="d-flex justify-start" :disabled="showLoading">
                    {{ $t("common.customized") }}
                  </v-tab>
                  <v-tab class="d-flex justify-start" :disabled="showLoading">
                    {{ $t("common.default") }}
                  </v-tab>

                  <v-tab-item class="py-0">
                    <v-card tile elevation="0" class="overflow-y-auto">
                      <v-card-subtitle
                        class="mt-0 pt-0 mb-2 font-weight-medium"
                      >
                        {{ $t("applySignature.customModels") }}
                      </v-card-subtitle>
                      <v-card-actions class="pt-0 mt-0">
                        <v-row class="ma-0">
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            lg="4"
                            xl="3"
                            v-for="item in allCustomTemplates"
                            :key="item.id"
                          >
                            <CardTemplate
                              :info="item"
                              custom
                              @selectCard="selectTemplateToApply"
                              no-actions
                            />
                          </v-col>
                        </v-row>
                      </v-card-actions>

                      <v-card-subtitle
                        class="mt-6 pt-0 mb-2 font-weight-medium"
                      >
                        {{ $t("applySignature.defaultModels") }}
                      </v-card-subtitle>
                      <v-card-actions class="pt-0 mt-0">
                        <v-row class="ma-0">
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            lg="4"
                            xl="3"
                            v-for="item in defaultTemplates"
                            :key="item.id"
                          >
                            <CardTemplate
                              :info="item"
                              @selectCard="selectTemplateToApply"
                              no-actions
                            />
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item class="py-0">
                    <v-card-subtitle class="mt-0 pt-0 mb-2 font-weight-medium">
                      {{ $t("applySignature.customModels") }}
                    </v-card-subtitle>
                    <v-card-actions>
                      <v-row class="ma-0">
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                          lg="4"
                          xl="3"
                          v-for="item in allCustomTemplates"
                          :key="item.id"
                        >
                          <CardTemplate
                            :info="item"
                            custom
                            @selectCard="selectTemplateToApply"
                            no-actions
                          />
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-tab-item>
                  <v-tab-item class="py-0">
                    <v-card-subtitle class="mt-0 pt-0 mb-2 font-weight-medium">
                      {{ $t("applySignature.defaultModels") }}
                    </v-card-subtitle>
                    <v-card-actions class="mt-0 pt-0">
                      <v-row class="ma-0 px-0">
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                          lg="4"
                          xl="3"
                          v-for="item in defaultTemplates"
                          :key="item.id"
                        >
                          <CardTemplate
                            :info="item"
                            @selectCard="selectTemplateToApply"
                            no-actions
                          />
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-tab-item>
                </v-tabs>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2" class="grey lighten-3 elevation-0">
              <v-card
                color="white"
                class="mx-auto px-4 py-6 elevation-0 pb-1 grey--text text--darken-1"
                tile
                :width="contentWidth"
                outlined
              >
                <Alert
                  v-if="hasTagsMissing && !isTemplateEmpty"
                  color="warning"
                  text
                  expansion
                  dense
                  :action-text="$t('common.details')"
                  action-icon="mdi-account-settings"
                >
                  <span>
                    {{ $t("applySignature.missingInfoAlertText") }}
                  </span>
                  <template #expansion>
                    <v-row class="ma-0 pa-0 mt-3" no-gutters>
                      <v-col cols="12" class="font-weight-medium">
                        {{
                          $t("applySignature.actionTextToCorrectInformation")
                        }}
                      </v-col>
                      <v-col cols="12">
                        <v-list dense>
                          <v-list-item
                            v-for="(info, i) of tagsMissing"
                            :key="i"
                            class="text--darken-2 px-0 warning--text text--darken-4"
                            @click.stop="
                              (selectedUser = info.user),
                                (showUserCardSignature = true)
                            "
                          >
                            <span class="font-weight-medium pr-2">
                              {{ info.user.name }}:
                            </span>
                            {{ info.tags.join(", ") }}.

                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-text="'ph-pencil-simple'"
                                  color="warning darken-4"
                                  size="16"
                                  right
                                  v-bind="attrs"
                                  v-on="on"
                                />
                              </template>
                              <span
                                >{{ $t("applySignature.editInformation") }}
                                {{ info.user.name }}</span
                              >
                            </v-tooltip>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </template>
                </Alert>

                <v-autocomplete
                  v-if="showSelectSignatureModel"
                  v-model="autocompleteSelected"
                  :items="allItems"
                  :item-text="
                    showSendAsEmails
                      ? (item) => ('user' in item ? item.user.name : item.name)
                      : 'name'
                  "
                  :item-value="itemKey"
                  clearable
                  open-on-clear
                  :label="$t('common.usersSelecteds')"
                  multiple
                  chips
                  outlined
                  class="mx-2 mt-5"
                  color="accent"
                  dense
                >
                  <template
                    v-slot:selection="{ attrs, item, parent, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      color="lighten-3"
                      :input-value="selected"
                      pill
                      class="ma-1 link pl-0"
                      @click.stop="firstUserSelected = item"
                    >
                      <v-avatar size="10" class="mr-2">
                        <img
                          :src="
                            item.photo ||
                            (item.user
                              ? item.user.photo
                              : '@/assets/user-default.png')
                          "
                        />
                      </v-avatar>
                      <TooltipIcon
                        v-if="!item.is_gmail_enabled && !showSendAsEmails"
                        :label="$t('common.emailServiceIsDisabled')"
                        max-width="400"
                        icon="ph-prohibit"
                        size="18"
                      />
                      <TooltipIcon
                        v-else-if="!item.is_editable"
                        :label="$t('common.userRestrictDescription')"
                        max-width="400"
                        icon="ph-fill ph-lock"
                        size="18"
                      />

                      <span class="pr-1 ml-2">
                        {{
                          showSendAsEmails
                            ? `${item.user ? item.user.name : ""} (${
                                item.email
                              })`
                            : item.name
                        }}
                      </span>

                      <v-icon
                        small
                        @click="parent.selectItem(item)"
                        v-text="'ph-x-circle'"
                      />
                    </v-chip>
                  </template>

                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item
                      v-on="on"
                      v-bind="attrs"
                      :input-value="itemIsSelected(item)"
                      :color="
                        itemIsSelected(item) ? 'accent lighten-3' : 'white'
                      "
                    >
                      <v-list-item-action class="px-0">
                        <v-checkbox
                          :input-value="itemIsSelected(item)"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="pr-2">
                            {{
                              showSendAsEmails
                                ? `${item.name} (${item.email})`
                                : item.name
                            }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>

                <Alert
                  v-if="hasRestrictedUsers"
                  :class="`mx-2 mb-4`"
                  :prominent="false"
                  color="info"
                  dense
                  :width="800"
                >
                  {{ $t("common.usersRestrictedsDescription") }}
                </Alert>

                <v-card
                  v-if="hasSelectedUsers"
                  outlined
                  max-height="320"
                  class="mx-2 mb-8 px-0"
                >
                  <v-card-subtitle>
                    {{ $t("common.viewingModel") }}
                    <span class="font-weight-bold">{{
                      selectedTemplateName
                    }}</span>
                  </v-card-subtitle>
                  <v-divider />
                  <v-card
                    flat
                    max-height="250"
                    class="overflow-y-auto px-4 pt-4"
                    v-if="htmlTemplate"
                  >
                    <TemplatePreview
                      :html="htmlTemplate"
                      :infoToRender="setPrimaryEmailInTemplatePreview"
                      signature-only
                    />
                  </v-card>
                </v-card>
              </v-card>

              <v-card
                class="mt-3 d-flex transparent px-0 mx-auto elevation-0"
                tile
                :width="contentWidth"
              >
                <v-btn
                  text
                  @click="steps = 1"
                  :disabled="loadingApplySignature"
                >
                  {{ $t("common.back") }}
                </v-btn>
                <v-spacer />
                <v-btn
                  elevation="0"
                  dark
                  color="accent"
                  @click="steps++"
                  :loading="loadingApplySignature"
                  data-cy="applyButton"
                >
                  {{ $t("action.next") }}
                </v-btn>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="3" class="grey lighten-3 elevation-0">
              <v-card
                color="white"
                class="mx-auto px-4 py-6 elevation-0 pb-1 grey--text text--darken-1"
                tile
                :width="contentWidth"
                outlined
              >
                <v-radio-group v-model="applySignatureMethod">
                  <v-card-text>
                    <!-- APLICAR AGORA -->
                    <v-row class="align-center">
                      <v-col cols="1" class="pr-0 d-flex justify-end">
                        <v-radio value="applyNow"></v-radio>
                      </v-col>

                      <v-col>
                        <v-card-title>
                          {{
                            $t("applySignature.applyEmailSignature")
                          }}</v-card-title
                        >
                        <v-card-subtitle>{{
                          $t("applySignature.applyEmailSignatureDescription")
                        }}</v-card-subtitle>
                      </v-col>
                    </v-row>

                    <!-- AGENDAR APLICAÇAO -->
                    <v-row class="align-center">
                      <v-col cols="1" class="pr-0 d-flex justify-end">
                        <v-radio value="schedule" />
                      </v-col>

                      <v-col>
                        <v-card-title>
                          {{
                            $t(
                              "applySignature.scheduleEmailSignatureApplication"
                            )
                          }}</v-card-title
                        >
                        <v-card-subtitle>{{
                          $t(
                            "applySignature.scheduleEmailSignatureApplicationDescription"
                          )
                        }}</v-card-subtitle>

                        <v-card-text>
                          <v-row>
                            <v-col
                              :class="`${
                                $vuetify.breakpoint.xs ? 'px-0' : 'pr-0'
                              }`"
                              lg="4"
                              md="6"
                              sm="6"
                              xs="6"
                              cols="12"
                            >
                              <v-menu
                                v-model="menuScheduleDate"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="auto"
                                offset-y
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    append-icon="ph-calendar-blank"
                                    :value="scheduleDate"
                                    label="Escolher data
                                  para aplicação"
                                    hint="Limite de 30 dias a
                                  partir de hoje"
                                    :placeholder="scheduleDateLabel"
                                    readonly
                                    hide-details
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                    :color="secondaryColor"
                                    :disabled="
                                      applySignatureMethod != 'schedule'
                                    "
                                  />
                                </template>
                                <v-date-picker
                                  v-model="scheduleDateSelected"
                                  :min="addDaysFormat(1)"
                                  :max="addDaysFormat(29)"
                                  :color="secondaryColor"
                                  @input="menuScheduleDate = false"
                                >
                                  <v-spacer></v-spacer>
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col
                              :class="`${
                                $vuetify.breakpoint.xs ? 'px-0' : 'pr-0'
                              } mr-2`"
                              lg="2"
                              md="2"
                              sm="4"
                              xs="5"
                              cols="12"
                            >
                              <v-text-field
                                v-model="hour"
                                :label="$t('common.hour')"
                                type="time"
                                hide-details
                                outlined
                                :disabled="applySignatureMethod != 'schedule'"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-col>
                    </v-row>

                    <!-- ENVIAR ASSINATURAS PARA OS USUÀRIOS -->
                    <v-row class="align-center" v-if="showStep">
                      <v-col cols="1" class="pr-0 d-flex justify-end">
                        <v-radio value="userWillApply" />
                      </v-col>

                      <v-col>
                        <v-card-title>
                          {{
                            $t("applySignature.usersApplyTheirOwnSignature")
                          }}</v-card-title
                        >
                        <v-card-subtitle>
                          {{
                            $t(
                              "applySignature.usersApplyTheirOwnSignatureDescription"
                            )
                          }}
                        </v-card-subtitle>
                      </v-col>
                    </v-row>

                    <!-- ENVIAR MODELO POR EMAIL -->
                    <v-row class="align-center">
                      <v-col cols="1" class="pr-0 d-flex justify-end">
                        <v-radio value="sendEmail" />
                      </v-col>

                      <v-col>
                        <v-card-title>
                          {{
                            $t("applySignature.sendSignatureViaEmail")
                          }}</v-card-title
                        >
                        <v-card-subtitle>
                          {{
                            $t(
                              "applySignature.sendSignatureViaEmailDescription"
                            )
                          }}
                        </v-card-subtitle>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-radio-group>
              </v-card>

              <v-card
                class="mt-3 d-flex transparent px-0 mx-auto elevation-0"
                tile
                :width="contentWidth"
              >
                <v-btn
                  text
                  @click="
                    steps = 2;
                    reset;
                  "
                  :disabled="loadingApplySignature"
                >
                  {{ $t("common.back") }}
                </v-btn>
                <v-spacer />
                <v-btn
                  elevation="0"
                  :dark="!scheduledWithoutDate"
                  color="accent"
                  :disabled="scheduledWithoutDate"
                  @click="openApplyDialog()"
                  :loading="loadingApplySignature"
                >
                  {{ $t("applySignature.applyModel") }}
                </v-btn>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-card>

    <!-- DIALOG DE CONFIRMAÇÃO DE APLICAÇÃO -->
    <HeaderDialog
      v-if="showApplyDialog"
      id="confirm_apply_signature"
      color="primary"
      :title="headerDialogTitle"
      :show="showApplyDialog"
      :persistent="false"
      :loading="loadingApplySignature"
      width="700"
      @close="reset"
      @action="applyTemplate"
      :closeText="$t('action.cancel')"
      button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
      :disabledAction="
        !scheduleDateSelected && applySignatureMethod == 'schedule'
      "
    >
      <template v-slot:body>
        <v-card-text class="text-body-1 pa-0 mb-5 mx-0 pt-3">
          <Alert
            v-if="applySignatureMethod === 'applyNow'"
            :prominent="false"
            color="warning"
            dense
            :width="800"
            class="mx-0"
          >
            {{ $t("applySignature.messageDelayToApply") }}
          </Alert>

          <span v-html="headerDialogDescription" />
        </v-card-text>

        <v-checkbox
          v-if="
            ['applyNow', 'schedule'].includes(applySignatureMethod) &&
            !isCompanyMicrosoft
          "
          v-model="notifyUsers"
          class="mt-5 mx-0 mb-0"
        >
          <template v-slot:label>
            <div class="d-flex align-center text-body-2">
              {{
                applySignatureMethod === "applyNow"
                  ? $t("applySignature.notifyUsers")
                  : $t("applySignature.notifyUsersScheduled")
              }}
              <v-tooltip top max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    size="18"
                    class="ml-1"
                    v-text="'ph-question'"
                  />
                </template>
                <span>
                  {{ $t("applySignature.infoNotifyUsers") }}
                </span>
              </v-tooltip>
            </div>
          </template>
        </v-checkbox>
      </template>
    </HeaderDialog>

    <PostponeRoamingDialog
      v-if="isCompanyMicrosoft"
      @close="handlePostponeClose()"
    />

    <DialogEditUserTags
      :user="selectedUser"
      :show="showUserCardSignature"
      @close="close"
      @update:user="updateUserInSelectedList"
    />
  </v-dialog>
</template>

<script>
import PostponeRoamingDialog from "@/components/microsoft/PostponeRoamingDialog.vue";
import CardTemplate from "@/components/sign/general/CardTemplate";
import TemplatePreview from "@/components/sign/general/TemplatePreview";
import DialogEditUserTags from "@/components/sign/tags/DialogEditUserTags.vue";

import {
  addDaysFormat,
  createDatetimeWithDateAndHour,
  formatDate,
} from "@/helpers/services/utils";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import {
  editableCompanyTags,
  userEditableTags,
} from "@/helpers/variables/tags.js";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "ApplySignatureSteps",

  components: {
    TemplatePreview,
    CardTemplate,
    DialogEditUserTags,
    PostponeRoamingDialog,
  },

  props: {
    currentSelected: { type: Array, default: () => [] },
    selectSignatureModel: { type: Boolean, default: false },
    selectedTemplate: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      applySignatureMethod: "applyNow",
      showLoading: false,
      notifyUsers: false,
      steps: 1,
      searchSignTemplate: "",
      usersWithoutSomeTag: [],
      loadingApplySignature: false,
      templatePreviewItem: null,
      vacation: {},
      showApplyDialog: false,
      editableTags: { ...userEditableTags, ...editableCompanyTags },
      selectedUser: {},
      showUserCardSignature: false,
      menuScheduleDate: false,
      scheduleDateSelected: "",
      hour: "23:59",
    };
  },

  async beforeMount() {
    if (this.templateId) {
      this.selectTemplateToApply(this.$route.query.id);
    }
    this.loading = false;
  },

  watch: {
    showSelectSignatureModel() {
      if (!this.showSelectSignatureModel) {
        this.steps = 1;
      }
    },

    "$route.query.id"() {
      if (this.templateId) {
        this.selectTemplateToApply(this.$route.query.id);
        this.tagsMissing = this.getSelectedTagsMissing();
      }
    },

    currentSelectedTemplate() {
      if (Object.keys(this.currentSelectedTemplate).length > 0) {
        this.tagsMissing = this.getSelectedTagsMissing();
      }
    },

    autocompleteSelected() {
      if (Object.keys(this.currentSelectedTemplate).length > 0) {
        this.tagsMissing = this.getSelectedTagsMissing();
      }
    },

    steps() {
      if (Object.keys(this.currentSelectedTemplate).length > 0) {
        this.tagsMissing = this.getSelectedTagsMissing();
      }
    },

    currentSelectedUsers() {
      if (this.hasSelectedUsers && this.showSelectSignatureModel) {
        this.templatePreviewItem = this.currentSelectedUsers[0];
      }
    },
  },

  methods: {
    ...mapMutations([
      "setSnackBar",
      "setAllEntitiesFilteredToApply",
      "togglePostponeRoamingDialog",
    ]),

    ...mapActions([
      "completeMission",
      "syncSendAs",
      "setSignatureToUsers",
      "sendUserUpdateAndApplyRequest",
      "sendSignatureModelViaEmail",
    ]),

    addDaysFormat,

    applyTemplateCatch(method, error) {
      this.setSnackBar({
        message: this.$t("errorMessages.setSignatureToUsers"),
        show: true,
        color: "error",
      });
      console.error(`${method}(): `, error);
    },

    applyTemplateFinally() {
      if (this.$route.query.id) this.$router.push({ query: {} });
      this.showSelectSignatureModel = false;
      this.templatePreviewItem = null;
      this.loadingApplySignature = false;
      this.reset();
    },

    async sendUsersApplyRequest() {
      const payload = this.showSendAsEmails
        ? {
            send_as: this.selectedSendAsEmails,
            template_key: this.currentSelectedTemplate.id,
          }
        : {
            emails: this.emailsFromSelectedUsers,
            template_key: this.currentSelectedTemplate.id,
          };

      const messages = {
        success: this.$t("successMessages.requestSent"),
        error: this.$t("errorMessages.requestSent"),
      };

      await this.sendUserUpdateAndApplyRequest({ payload, messages })
        .then(() => {
          this.currentSelectedUsers = [];
          this.setAllEntitiesFilteredToApply([]);
          this.completeMission("apply_email_signature");
          this.$emit("showSuccessHintScreen", this.applySignatureMethod);
          this.showSelectSignatureModel = false;
        })
        .catch((error) => {
          this.applyTemplateCatch("sendUsersApplyRequest", error);
        })
        .finally(() => {
          this.applyTemplateFinally();
        });
    },

    async sendSignatureViaEmail() {
      const payload = this.showSendAsEmails
        ? {
            send_as: this.selectedSendAsEmails,
            template_key: this.currentSelectedTemplate.id,
          }
        : {
            emails: this.emailsFromSelectedUsers,
            template_key: this.currentSelectedTemplate.id,
          };

      const messages = {
        success: this.$t("successMessages.signatureEmailSent"),
        error: this.$t("errorMessages.signatureEmailSent"),
      };

      await this.sendSignatureModelViaEmail({ payload, messages })
        .then(() => {
          this.currentSelectedUsers = [];
          this.setAllEntitiesFilteredToApply([]);
          this.completeMission("apply_email_signature");
          this.$emit("showSuccessHintScreen", this.applySignatureMethod);
          this.showSelectSignatureModel = false;
        })
        .catch((error) => {
          this.applyTemplateCatch("sendSignatureViaEmail", error);
        })
        .finally(() => {
          this.applyTemplateFinally();
        });
    },

    async setSignature() {
      const schedule =
        this.scheduleDateSelected && this.applySignatureMethod == "schedule";

      const payload = this.showSendAsEmails
        ? {
            emails: this.selectedSendAsEmails,
            template_key: this.currentSelectedTemplate.id,
            send_as: this.showSendAsEmails,
            notify_users: this.notifyUsers,
          }
        : {
            emails: this.emailsFromSelectedUsers,
            template_key: this.currentSelectedTemplate.id,
            notify_users: this.notifyUsers,
          };
      if (schedule) {
        payload.schedule_date = createDatetimeWithDateAndHour(
          this.scheduleDateSelected,
          this.hour
        );
      }

      await this.setSignatureToUsers(payload)
        .then(() => {
          this.currentSelectedUsers = [];
          this.setAllEntitiesFilteredToApply([]);

          let message = "";

          if (schedule) {
            message = this.$t("successMessages.scheduleSignature", {
              date: this.scheduleDate,
            });
          } else {
            message = this.$t("successMessages.setSignatureToUsers");
          }
          this.setSnackBar({
            message,
            show: true,
            color: "success",
          });
          this.$emit("update:table-users-signature", payload);
          this.completeMission("apply_email_signature");
          this.$emit("showSuccessHintScreen", this.applySignatureMethod);
          this.showSelectSignatureModel = false;
        })
        .catch((error) => {
          this.applyTemplateCatch("setSignature", error);
        })
        .finally(() => {
          this.applyTemplateFinally();
        });
    },

    async applyTemplate() {
      if (this.needSuiteMsActivation) {
        return this.togglePostponeRoamingDialog(true);
      }
      this.loadingApplySignature = true;
      if (this.applySignatureMethod == "userWillApply") {
        return await this.sendUsersApplyRequest();
      } else if (this.applySignatureMethod == "sendEmail") {
        return await this.sendSignatureViaEmail();
      } else {
        return await this.setSignature();
      }
    },

    async close(hasSaved) {
      if (hasSaved) {
        this.$emit("update:users-table-data");
      }
      this.showUserCardSignature = false;
      this.selectedUser = {};
    },

    resetEmailTemplateApply() {
      this.templatePreviewItem = null;
      this.currentSelectedUsers = [];
      this.setAllEntitiesFilteredToApply([]);
      this.showSelectSignatureModel = false;
    },

    updateUserInSelectedList(updatedUser) {
      const index = this.currentSelectedUsers.findIndex(
        (user) => user.id_google === updatedUser.id_google
      );

      if (index >= 0) {
        this.currentSelectedUsers[index] = updatedUser;
        this.templatePreviewItem = this.currentSelectedUsers[index];
      }

      this.tagsMissing = this.getSelectedTagsMissing();
    },

    findUserByEmail(email) {
      return this.users.find((user) => user.email === email);
    },

    selectTemplateToApply(id) {
      this.currentSelectedTemplate = this.allTemplates.find(
        (template) => template.id === id
      );
      if (this.currentSelectedTemplate) {
        this.steps++;
      } else {
        this.setSnackBar({
          message: errorMessages.select_template_to_apply,
          show: true,
          color: "error",
        });
        setTimeout(() => this.$router.push("/sign/template"), 3000);
      }
    },

    getSelectedTagsMissing() {
      if (!this.isTemplateEmpty) {
        const editableTagsKeys = Object.keys(userEditableTags);
        return this.currentSelectedUsers.reduce((acc, user) => {
          const userInfo = Object.entries(user);
          let userMissingTags = [];
          userInfo.forEach(([key, value]) => {
            if (editableTagsKeys.includes(key)) {
              if (
                !value &&
                this.currentSelectedTemplate.html.includes(
                  userEditableTags[key].label
                )
              ) {
                userMissingTags.push(
                  this.$t(userEditableTags[key].translatePath)
                );
              }
            }
          });
          if (userMissingTags.length > 0) {
            acc[user.name] = {
              tags: userMissingTags,
              user: user,
            };
          }

          return acc;
        }, {});
      }

      return {};
    },

    itemIsSelected(item) {
      return !!this.currentSelectedUsers.find(
        (user) => user[this.itemKey] == item[this.itemKey]
      );
    },
    reset() {
      this.showApplyDialog = false;
      this.scheduleDateSelected = "";
      this.hour = "23:59";
      this.notifyUsers = false;
    },

    openApplyDialog() {
      if (this.needSuiteMsActivation) {
        this.togglePostponeRoamingDialog(!this.postponeRoaming);
      } else {
        this.showApplyDialog = true;
      }
    },

    handlePostponeClose() {
      if (this.needSuiteMsActivation) {
        return;
      }
      this.showApplyDialog = true;
    },
  },

  computed: {
    ...mapGetters([
      "secondaryColor",
      "contentWidth",
      "users",
      "sendAsEmails",
      "allEmailsInCompany",
      "allTemplates",
      "customTemplates",
      "defaultTemplates",
      "allVacationTemplates",
      "company",
      "displayOnlyPrimaryEmails",
      "isCompanyMicrosoft",
      "postponeRoaming",
    ]),

    scheduleDateLabel() {
      if (this.$vuetify.breakpoint.xs) {
        return "Data de agendamento";
      }
      return "";
    },

    showStep() {
      return !this.isCompanyMicrosoft;
    },

    scheduleDate() {
      if (!this.scheduleDateSelected) {
        return null;
      }
      return formatDate(this.scheduleDateSelected);
    },

    scheduledWithoutDate() {
      return (
        !this.scheduleDateSelected && this.applySignatureMethod === "schedule"
      );
    },

    currentSelectedUsers: {
      get() {
        return this.currentSelected;
      },
      set(value) {
        this.$emit("update:current-selected", value);
      },
    },

    currentSelectedTemplate: {
      get() {
        return this.selectedTemplate;
      },
      set(value) {
        this.$emit("update:current-selected-template", value);
      },
    },

    showSelectSignatureModel: {
      get() {
        return this.selectSignatureModel;
      },
      set(value) {
        this.$emit("update:select-signature-model", value);
      },
    },

    allItems() {
      return this.showSendAsEmails ? this.allEmailsInCompany : this.users;
    },

    itemKey() {
      return this.showSendAsEmails ? "id" : "id_google";
    },

    selectedNumber() {
      return this.currentSelectedUsers.length;
    },

    isTemplateEmpty() {
      return !this.currentSelectedTemplate.html ? true : false;
    },

    emailsFromSelectedUsers() {
      return this.currentSelectedUsers.map((user) => user.email);
    },

    selectedUsersFromSendAs() {
      return this.currentSelectedUsers.map((item) => item.user.email);
    },

    selectedSendAsEmails() {
      return this.currentSelectedUsers.reduce((acc, next) => {
        if (!acc[next.user.id_google]) acc[next.user.id_google] = [];
        acc[next.user.id_google].push(next.email);
        return acc;
      }, {});
    },

    firstUserSelected: {
      get() {
        if (this.hasSelectedUsers) {
          return this.templatePreviewItem || this.currentSelectedUsers[0];
        }
        return {};
      },
      set(newValue) {
        if (newValue) {
          let newItem =
            typeof newValue === String
              ? this.currentSelectedUsers.find(
                  (item) => item[this.itemKey] == newValue
                )
              : this.currentSelectedUsers.find(
                  (item) => item[this.itemKey] == newValue[this.itemKey]
                );
          this.templatePreviewItem =
            this.showSendAsEmails && newItem.user
              ? this.findUserByEmail(newItem.user.email)
              : newItem;
        } else {
          this.templatePreviewItem = null;
        }
      },
    },

    isLoading() {
      if (this.showSendAsEmails) {
        return this.loadingSyncSendAs || this.showLoading || this.loading;
      }
      return this.showLoading || this.loading;
    },

    templateId() {
      return this.$route.query.id || null;
    },

    templateKey() {
      return this.$route.query.key || null;
    },

    setPrimaryEmailInTemplatePreview() {
      return this.firstUserSelected.user
        ? this.firstUserSelected.user
        : this.firstUserSelected;
    },

    allCustomTemplates() {
      return this.searchSignTemplate != ""
        ? this.customTemplates.filter((template) =>
            template.name
              .toUpperCase()
              .match(this.searchSignTemplate.toUpperCase())
          )
        : this.customTemplates;
    },

    hasSelectedUsers() {
      return this.selectedNumber > 0;
    },

    selectedTemplateName() {
      return this.currentSelectedTemplate.name;
    },

    htmlTemplate() {
      return this.currentSelectedTemplate.html;
    },

    autocompleteSelected: {
      get() {
        return this.currentSelectedUsers.map((item) => item[this.itemKey]);
      },
      set(newValue) {
        this.currentSelectedUsers = newValue.map((value) =>
          this.allItems.find((item) => item[this.itemKey] == value)
        );
      },
    },

    hasRestrictedUsers() {
      return this.currentSelectedUsers.some(({ is_editable }) => !is_editable);
    },

    hasTagsMissing() {
      return Object.entries(this.tagsMissing).length > 0;
    },

    tagsMissing: {
      get() {
        return this.usersWithoutSomeTag;
      },
      set(newVal) {
        this.usersWithoutSomeTag = newVal;
      },
    },
    showSendAsEmails() {
      return !this.displayOnlyPrimaryEmails;
    },

    headerDialogTitle() {
      if (this.applySignatureMethod === "applyNow") {
        return this.$t("applySignature.applyEmailSignature");
      } else if (this.applySignatureMethod === "schedule") {
        return this.$t("applySignature.scheduleEmailSignatureApplication");
      } else if (this.applySignatureMethod === "userWillApply") {
        return this.$t("applySignature.usersApplyTheirOwnSignature");
      } else if (this.applySignatureMethod === "sendEmail") {
        return this.$t("applySignature.sendSignatureViaEmail");
      } else {
        return this.$t("applySignature.applyEmailSignature");
      }
    },

    headerDialogDescription() {
      if (this.applySignatureMethod === "applyNow") {
        return this.$t("applySignature.confirmApply");
      } else if (this.applySignatureMethod === "schedule") {
        return this.$t("applySignature.scheduleEmailSignatureDescription");
      } else if (this.applySignatureMethod === "userWillApply") {
        return this.$t("applySignature.userWillapplyEmailSignatureDescription");
      } else if (this.applySignatureMethod === "sendEmail") {
        return this.$t("applySignature.sendSignatureViaEmailDescription");
      } else {
        return this.$t("applySignature.confirmApply");
      }
    },

    needSuiteMsActivation() {
      return this.isCompanyMicrosoft && !this.postponeRoaming;
    },
  },
};
</script>
