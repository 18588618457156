<template>
  <v-form style="width: 100%" :disabled="loading" ref="usersFilter">
    <UosPicker
      v-if="!isCompanyMicrosoft"
      :selected="filteringBy.ou"
      @update="filteringBy.ou = $event"
      return-path
      single-choice
      subtitle
    />
    <v-card-subtitle class="font-weight-medium pt-0">
      {{ $t("userInformations.departament") }}
      <TooltipIcon
        icon="ph-info"
        color="secondary lighten-3"
        :label="$t('userInformations.departamentDescription')"
      />
    </v-card-subtitle>
    <v-card-text class="mb-3">
      <v-text-field
        v-model="filteringBy.departament"
        outlined
        hide-details
        clearable
      />
    </v-card-text>

    <v-card-subtitle class="font-weight-medium pt-0">
      {{ $t("userInformations.office") }}
      <TooltipIcon
        icon="ph-info"
        color="secondary lighten-3"
        :label="$t('userInformations.officeDescription')"
      />
    </v-card-subtitle>
    <v-card-text class="mb-3">
      <v-text-field
        v-model="filteringBy.position"
        outlined
        hide-details
        clearable
      />
    </v-card-text>

    <v-card-subtitle class="font-weight-medium pt-0 pb-0">
      {{ $t("userInformations.function") }}
      <TooltipIcon
        icon="ph-info"
        color="secondary lighten-3"
        :label="$t('userInformations.functionDescription')"
      />
    </v-card-subtitle>
    <v-card-text class="mb-5">
      <v-radio-group v-model="filteringBy.is_google_admin" row hide-details>
        <v-radio :label="$t('common.admin')" :value="true"></v-radio>
        <v-radio :label="$t('common.member')" :value="false"></v-radio>
        <v-radio :label="$t('common.anyType')" :value="''"></v-radio>
      </v-radio-group>
    </v-card-text>

    <SignatureTemplatePicker
      v-model="filteringBy.applied_signature_key"
      :selected="filteringBy.applied_signature_key"
      @update="filteringBy.applied_signature_key = $event"
      single-choice
    />

    <VacationTemplatePicker
      v-if="!showSendAs && !isCompanyMicrosoft"
      v-model="filteringBy.applied_vacation_key"
      :selected="filteringBy.applied_vacation_key"
      @update="filteringBy.applied_vacation_key = $event"
      single-choice
    />

    <v-card-actions class="justify-end">
      <v-btn
        text
        color="secondary lighten-3"
        :disabled="emptySearch"
        rounded
        large
        elevation="0"
        class="text-none text-body-1 font-weight-medium px-4"
        @click="clear"
      >
        {{ $t("action.clear") }}
      </v-btn>
      <v-spacer />
      <v-btn
        :dark="!emptySearch"
        :color="primaryColor"
        :disabled="emptySearch"
        rounded
        large
        elevation="0"
        class="text-none text-body-1 font-weight-medium pl-4 pr-6"
        :loading="loading"
        @click="searchFilters"
      >
        <v-icon v-text="'ph-magnifying-glass'" left />
        {{ $t("action.search") }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import SignatureTemplatePicker from "@/components/forms/field_pickers/SignatureTemplatePicker";
import VacationTemplatePicker from "@/components/forms/field_pickers/VacationTemplatePicker";

import { hasChanges } from "@/helpers/services/utils";

import { mapGetters } from "vuex";
import UosPicker from "../field_pickers/UosPicker.vue";

export default {
  name: "UsersFiltersForm",

  props: {
    applied: { type: Object, default: () => {} },
    loading: { type: Boolean, default: false },
    showSendAs: { type: Boolean, default: false },
  },

  components: {
    SignatureTemplatePicker,
    VacationTemplatePicker,
    UosPicker,
  },

  data() {
    return {
      localFilters: {},
    };
  },

  computed: {
    ...mapGetters(["secondaryColor", "primaryColor", "isCompanyMicrosoft"]),

    filteringBy: {
      get() {
        return this.localFilters;
      },
      set(newVal) {
        this.localFilters = newVal;
      },
    },

    emptySearch() {
      return Object.values(this.filteringBy).every(
        (item) => item === "" || item === null || item.length < 1
      );
    },

    defaultFilters() {
      return {
        ou: "",
        departament: "",
        position: "",
        is_google_admin: "",
        applied_signature_key: "",
        applied_vacation_key: "",
      };
    },
  },

  watch: {
    applied() {
      this.update();
    },
  },

  methods: {
    hasChanges,

    searchFilters() {
      const validAppliedFilters = Object.entries(this.filteringBy).reduce(
        (acc, item) => {
          const key = item[0];
          const value = item[1];
          if (value !== null && value.length !== 0) {
            acc[key] = value;
          } else {
            acc[key] = "";
          }

          return acc;
        },
        {}
      );

      this.filteringBy = { ...this.defaultFilters, ...validAppliedFilters };
      this.$emit("search", this.filteringBy);
    },

    clear() {
      this.$nextTick(() => {
        if (this.$refs.usersFilter) {
          this.$refs.usersFilter.reset();
        }
      });

      this.update();

      this.$emit("clear", this.defaultFilters);
    },

    update() {
      if (this.applied && Object.keys(this.applied).length > 0) {
        const validAppliedFilters = Object.entries(this.applied).reduce(
          (acc, item) => {
            const key = item[0];
            const value = item[1];
            if (value !== null && value.length !== 0) {
              acc[key] = value;
            } else {
              acc[key] = "";
            }

            return acc;
          },
          {}
        );

        this.filteringBy = { ...this.defaultFilters, ...validAppliedFilters };
      } else {
        this.reset();
      }
    },

    reset() {
      this.filteringBy = Object.assign({}, this.defaultFilters);
    },
  },

  beforeMount() {
    this.update();
  },
};
</script>
