<template>
  <!-- TODO: finalizar a implementação desse componente -->
  <v-form ref="orderByTable">
    <!-- COLUNA PARA ORDERNAR -->
    <v-card-subtitle class="font-weight-medium pt-0 pb-0 mb-2">
      Coluna da tabela
    </v-card-subtitle>
    <v-card-text>
      <v-chip-group v-model="currentOrder.orderBy" column>
        <v-chip
          filter
          outlined
          v-for="(item, i) in orderOptions"
          :key="i"
          :value="item.value"
        >
          {{ item.text }}
        </v-chip>
      </v-chip-group>
    </v-card-text>

    <!-- ORDENAÇÃO -->
    <v-card-subtitle class="font-weight-medium pt-0 pb-0 mt-4 mb-2">
      Tipo de ordenação
    </v-card-subtitle>

    <v-card-text>
      <v-radio-group v-model="currentOrder.sortDesc" row hide-details>
        <v-radio
          v-for="(item, i) in sortOptions"
          :key="i"
          :label="item.text"
          :value="item.value"
        ></v-radio>
      </v-radio-group>
    </v-card-text>

    <v-card-actions class="mt-4">
      <v-btn
        text
        color="secondary lighten-3"
        rounded
        large
        elevation="0"
        class="text-none text-body-1 font-weight-medium px-4"
        @click="clear"
        :disabled="loading"
      >
        Limpar
      </v-btn>
      <v-spacer />
      <v-btn
        :dark="!loading"
        :color="primaryColor"
        rounded
        large
        elevation="0"
        class="text-none text-body-1 font-weight-medium pl-4 pr-6"
        :loading="loading"
        @click="apply"
      >
        <v-icon v-text="'ph-funnel-simple'" left />
        Ordenar
      </v-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SortByPicker",

  props: {
    loading: { type: Boolean, default: false },
    order: { type: Object, required: true },
    orderOptions: { type: Array, required: true },
  },

  data() {
    return {
      localOrder: {},
    };
  },

  computed: {
    ...mapGetters(["primaryColor", "secondaryColor"]),

    currentOrder: {
      get() {
        return this.localOrder;
      },
      set(newVal) {
        this.localOrder = newVal;
      },
    },

    sortOptions() {
      return [
        {
          text: "Crescente",
          icon: "ph-sort-ascending",
          value: false,
        },
        {
          text: "Decrescente",
          icon: "ph-sort-descending",
          value: true,
        },
      ];
    },
  },

  watch: {
    order() {
      this.currentOrder.orderBy = this.order.orderBy[0]
        ? this.order.orderBy[0]
        : "name";
      this.currentOrder.sortDesc = this.order.sortDesc[0]
        ? this.order.sortDesc[0]
        : false;
    },
  },

  methods: {
    clear() {
      this.$nextTick(() => {
        if (this.$refs.orderByTable) {
          this.$refs.orderByTable.reset();
        }
      });

      this.$emit("clear");
    },

    apply() {
      const payload = Object.entries(this.currentOrder).reduce((acc, item) => {
        const key = item[0];
        let value = [];
        value.push(item[1]);
        acc[key] = value;
        return acc;
      }, {});

      this.$emit("sort-by", payload);
    },
  },

  beforeMount() {
    this.currentOrder.orderBy = this.order.orderBy[0]
      ? this.order.orderBy[0]
      : "name";
    this.currentOrder.sortDesc = this.order.sortDesc[0]
      ? this.order.sortDesc[0]
      : false;
  },
};
</script>
